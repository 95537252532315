/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "./helpers";

.w-0 {
  inline-size: 0;
}

@each $size in helpers.$sizes {
  .w-#{$size} {
    inline-size: var(--scale-#{$size});
  }
}

.w-full {
  inline-size: 100%;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.max-w-max {
  max-inline-size: max-content;
}

.max-w-min {
  max-inline-size: min-content;
}

.min-w-min {
  min-inline-size: min-content;
}
