/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  --dremio--default--dark--mode: #b8bcc2;
  --dremio--hover--dark--mode: #80dfe3;
  --dremio--color--pale--navy: #e4f2f7;
}

:root {
  --color--cyan--25: #f1fafb;
  --color--cyan--50: #e9f5f9;
  --color--cyan--75: #d1eef3;
  --color--cyan--100: #c6e9ef;
  --color--cyan--150: #b4e3e9;
  --color--cyan--200: #8ed4df;
  --color--cyan--250: #26c8d1;
  --color--cyan--300: #43b8c9;
  --color--cyan--400: #2e92a1;
  --color--cyan--450: #008489;
  --color--cyan--500: #206771;
  --color--cyan--600: #285056;
  --color--cyan--700: #14373c;
  --color--cyan--800: #0d2528;
  --color--gray--25: #fbfbfb;
  --color--gray--50: #f6f7f8;
  --color--gray--75: #eeeff1;
  --color--gray--100: #e4e6e9;
  --color--gray--150: #d2d6da;
  --color--gray--200: #b0b7bf;
  --color--gray--300: #939da7;
  --color--gray--400: #7b8288;
  --color--gray--500: #505862;
  --color--gray--600: #454c52;
  --color--gray--700: #32383e;
  --color--gray--800: #202124;
  --color--gray--900: #101214;
  --color--red--50: #fdeded;
  --color--red--200: #ff7575;
  --color--red--300: #ff5252;
  --color--red--400: #ca3f32;
  --color--red--500: #ad3021;
  --color--red--700: #3b2626;
  --color--orange--50: #fff4e5;
  --color--orange--150: #ffb85d;
  --color--orange--200: #ffa940;
  --color--orange--900: #443728;
  --color--green--50: #edf7ed;
  --color--green--200: #8bd282;
  --color--green--300: #5abd4a;
  --color--green--700: #293a2a;
  --color--blue--50: #e9f5f9;
  --color--blue--300: #5ab3ff;
  --color--blue--400: #0684f9;
  --color--blue--700: #1c3146;
  --color--navy--500: #485767;
  --color--navy--700: #2a394a;
  --color--indigo--300: #8ca4e9;
  --color--indigo--500: #6c6da4;
  --color--indigo--700: #47498d;
  --color--indigo-vivid--500: #5a5dee;
  /* Avatar Colors */
  --color--orange-warm--300: #f79472;
  --color--violet-warm--500: #9a51bf;
  --color--red-cool--500: #d02362;
  --color--blue-warm--300: #3b84cb;
  --color--magenta--300: #cc88ae;
  --color--mint--300: #64c5bf;
}

/**
 * Semantic color aliases
 */
:root {
  --color--brand--25: var(--color--cyan--25);
  --color--brand--50: var(--color--cyan--50);
  --color--brand--75: var(--color--cyan--75);
  --color--brand--100: var(--color--cyan--100);
  --color--brand--150: var(--color--cyan--150);
  --color--brand--200: var(--color--cyan--200);
  --color--brand--250: var(--color--cyan--250);
  --color--brand--300: var(--color--cyan--300);
  --color--brand--400: var(--color--cyan--400);
  --color--brand--450: var(--color--cyan--450);
  --color--brand--500: var(--color--cyan--500);
  --color--brand--600: var(--color--cyan--600);
  --color--brand--700: var(--color--cyan--700);
  --color--brand--800: var(--color--cyan--800);
  --color--neutral--25: var(--color--gray--25);
  --color--neutral--50: var(--color--gray--50);
  --color--neutral--75: var(--color--gray--75);
  --color--neutral--100: var(--color--gray--100);
  --color--neutral--150: var(--color--gray--150);
  --color--neutral--200: var(--color--gray--200);
  --color--neutral--300: var(--color--gray--300);
  --color--neutral--500: var(--color--gray--500);
  --color--neutral--400: var(--color--gray--400);
  --color--neutral--600: var(--color--gray--600);
  --color--neutral--700: var(--color--gray--700);
  --color--neutral--800: var(--color--gray--800);
  --color--neutral--900: var(--color--gray--900);
  --color--danger--50: var(--color--red--50);
  --color--danger--200: var(--color--red--200);
  --color--danger--300: var(--color--red--300);
  --color--danger--400: var(--color--red--400);
  --color--danger--500: var(--color--red--500);
  --color--danger--700: var(--color--red--700);
  --color--warning--50: var(--color--orange--50);
  --color--warning--150: var(--color--orange--150);
  --color--warning--200: var(--color--orange--200);
  --color--warning--900: var(--color--orange--900);
  --color--success--50: var(--color--green--50);
  --color--success--200: var(--color--green--200);
  --color--success--300: var(--color--green--300);
  --color--success--700: var(--color--green--700);
  --color--info--50: var(--color--blue--50);
  --color--info--300: var(--color--blue--300);
  --color--info--400: var(--color--blue--400);
  --color--info--700: var(--color--blue--700);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  --font-family--default: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    --font-family--default: "InterVariable", sans-serif;
    font-optical-sizing: auto;
  }
}
:root {
  --font-family--mono: "Fira Code", monospace;
  /* @deprecated */
  --dremio--font-family: var(--font-family--default);
  /* @deprecated */
  --dremio--font-family--monospace: var(--font-family--mono);
  /**
    Use rem in case the user has overridden their browser's default font size (not zoom)
  */
  --dremio--font-size--xs: calc(10rem / 14);
  --dremio--font-size--sm: calc(12rem / 14);
  --dremio--font-size--md: calc(14rem / 14);
  --dremio--font-size--lg: calc(16rem / 14);
  --dremio--font-size--xl: calc(18rem / 14);
  --dremio--font-size--default: var(--dremio--font-size--md);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  --dremio--radius--05: calc(var(--dremio--radius--1) * 0.5);
  --dremio--radius--1: 4px;
  --dremio--radius--105: calc(var(--dremio--radius--1) * 1.5);
  --dremio--radius--2: calc(var(--dremio--radius--1) * 2);
}

:root {
  --scale-1: 8px;
  --scale-05: calc(var(--scale-1) * 0.5);
  --scale-075: calc(var(--scale-1) * 0.75);
  --scale-105: calc(var(--scale-1) * 1.5);
  --scale-2: calc(var(--scale-1) * 2);
  --scale-205: calc(var(--scale-1) * 2.5);
  --scale-3: calc(var(--scale-1) * 3);
  --scale-4: calc(var(--scale-1) * 4);
  --scale-405: calc(var(--scale-1) * 4.5);
  --scale-5: calc(var(--scale-1) * 5);
  --scale-505: calc(var(--scale-1) * 5.5);
  --scale-6: calc(var(--scale-1) * 6);
  --scale-7: calc(var(--scale-1) * 7);
  --scale-8: calc(var(--scale-1) * 8);
  --scale-9: calc(var(--scale-1) * 9);
  --scale-905: calc(var(--scale-1) * 9.5);
  --scale-10: calc(var(--scale-1) * 10);
  --scale-rel-1: calc(8em / 14);
  --scale-rel-05: calc(var(--scale-rel-1) * 0.5);
  --scale-rel-075: calc(var(--scale-rel-1) * 0.75);
  --scale-rel-105: calc(var(--scale-rel-1) * 1.5);
  --scale-rel-2: calc(var(--scale-rel-1) * 2);
  --scale-rel-205: calc(var(--scale-rel-1) * 2.5);
  --scale-rel-3: calc(var(--scale-rel-1) * 3);
  --scale-rel-4: calc(var(--scale-rel-1) * 4);
  --scale-rel-405: calc(var(--scale-rel-1) * 4.5);
  --scale-rel-5: calc(var(--scale-rel-1) * 5);
  --scale-rel-505: calc(var(--scale-rel-1) * 5.5);
  --scale-rel-6: calc(var(--scale-rel-1) * 6);
  --scale-rel-7: calc(var(--scale-rel-1) * 7);
  --scale-rel-8: calc(var(--scale-rel-1) * 8);
  --scale-rel-9: calc(var(--scale-rel-1) * 9);
  --scale-rel-905: calc(var(--scale-rel-1) * 9.5);
  --scale-rel-10: calc(var(--scale-rel-1) * 10);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  --dremio--shadow--layer-1: 4px 4px 16px rgba(0 0 0 / 15%);
  --dremio--shadow--top: 0 -1px 8px rgba(0 0 0 / 10%);
  --dremio--shadow--right: 1px 0 8px rgba(0 0 0 / 10%);
  --dremio--shadow--bottom: 0 1px 8px rgba(0 0 0 / 10%);
  --dremio--shadow--left: -1px 0 8px rgba(0 0 0 / 10%);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  --dremio--spacing--05: calc(var(--dremio--spacing--1) * 0.5);
  --dremio--spacing--1: 8px;
  --dremio--spacing--105: calc(var(--dremio--spacing--1) * 1.5);
  --dremio--spacing--2: calc(var(--dremio--spacing--1) * 2);
  --dremio--spacing--3: calc(var(--dremio--spacing--1) * 3);
  --dremio--spacing--4: calc(var(--dremio--spacing--1) * 4);
  --dremio--spacing--5: calc(var(--dremio--spacing--1) * 5);
  --dremio--spacing--6: calc(var(--dremio--spacing--1) * 6);
  --dremio--spacing--7: calc(var(--dremio--spacing--1) * 7);
  --dremio--spacing--8: calc(var(--dremio--spacing--1) * 8);
  --dremio--spacing--9: calc(var(--dremio--spacing--1) * 9);
  --dremio--spacing--10: calc(var(--dremio--spacing--1) * 10);
  --dremio--spacing--15: calc(var(--dremio--spacing--1) * 15);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-light {
  --bg--sunken: white;
  --bg--default: white;
  --bg--nav--sonar: var(--color--navy--700);
  --color--sunken: var(--color--neutral--800);
  --color--default: var(--color--neutral--800);
  --color--nav--sonar: white;
}

.dremio-dark {
  --bg--sunken: var(--color--gray--900);
  --bg--default: var(--fill--primary);
  --bg--nav--sonar: var(--fill--primary);
  --color--sunken: var(--text--faded);
  --color--default: var(--text--primary);
  --color--nav--sonar: var(--color--neutral--100);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.centered-container {
  align-items: center;
  block-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.no-underline {
  text-decoration: none !important;
}

.dremio-spinner-overlay,
.overlay-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.show-on-hover {
  visibility: hidden;
}

/* stylelint-disable selector-class-pattern */
.show-on-hover__target:hover .show-on-hover,
.show-on-hover__target:focus-within .show-on-hover {
  visibility: visible;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 24px;
}

.metric__label,
.metric__value {
  z-index: 2;
  padding: 0 4px;
  font-size: 12px;
}

.metric__bar {
  position: absolute;
  height: 24px;
  background-color: rgba(67, 184, 201, 0.35);
  z-index: 1;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-typography-monospace {
  font-family: var(--dremio--font-family--monospace);
  font-feature-settings: "zero" on;
  font-variant-ligatures: none;
  font-weight: 400;
}

.dremio-typography-bold {
  font-weight: 600;
}

.dremio-typography-disabled {
  color: var(--text--disabled);
}

.dremio-typography-extra-large {
  font-size: 18px;
  line-height: 28px;
}

.dremio-typography-large {
  font-size: 16px;
  line-height: 24px;
}

.dremio-typography-medium {
  font-size: 14px;
  line-height: 20px;
}

.dremio-typography-small {
  font-size: 12px;
  line-height: 18px;
}

.dremio-typography-extra-small {
  font-size: 10px;
  line-height: 14px;
}

.dremio-typography-less-important {
  color: var(--text--faded);
}

/**
 * This class should be used whenever strictly numeric data (such as timestamps, durations,
 * quantities, etc.) is displayed vertically and the length of the content may be significant.
 * This would be the case in table columns, <li>s where numeric content naturally aligns, etc.
 */
.dremio-typography-tabular-numeric {
  font-variant-numeric: lining-nums tabular-nums;
  letter-spacing: -0.025em;
}

.align-baseline, .form-control {
  vertical-align: baseline;
}

@keyframes opacity {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
.animate-opacity {
  animation-name: opacity;
}

.animate-pulse {
  animation-direction: alternate;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
}

@keyframes opacity-full {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate-fade-in {
  animation-name: opacity-full;
  animation-duration: 60ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
}

.transition-fade-in-out-delayed {
  opacity: 0;
  transition-timing-function: ease;
  transition-property: opacity;
}
.transition-fade-in-out-delayed-enter-active, .transition-fade-in-out-delayed-appear-active {
  transition-delay: 120ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
}
.transition-fade-in-out-delayed-exit-active {
  transition-duration: 60ms;
}
.transition-fade-in-out-delayed-enter-active {
  transition-duration: 240ms;
}
.transition-fade-in-out-delayed-enter, .transition-fade-in-out-delayed-appear {
  opacity: 0;
}
.transition-fade-in-out-delayed-enter-active, .transition-fade-in-out-delayed-appear-active, .transition-fade-in-out-delayed-appear-done, .transition-fade-in-out-delayed-enter-done {
  opacity: 1;
}
.transition-fade-in-out-delayed-exit {
  opacity: 1;
}
.transition-fade-in-out-delayed-exit-active {
  opacity: 0;
}

.animate-icon-rotate {
  transition: transform 50ms;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.transition-medium, .hover\:bg-brand-subtle:hover {
  transition-duration: 100ms;
  transition-timing-function: ease;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.bg-background {
  background: var(--fill--primary);
  color: var(--text--primary);
}

.bg-secondary {
  background: var(--fill--secondary);
  color: var(--text--primary);
}

.bg-cyan-25 {
  background-color: var(--color--cyan--25);
  color: var(--color--neutral--800);
}

.bg-cyan-50 {
  background-color: var(--color--cyan--50);
  color: var(--color--neutral--800);
}

.bg-cyan-75 {
  background-color: var(--color--cyan--75);
  color: var(--color--neutral--800);
}

.bg-cyan-100 {
  background-color: var(--color--cyan--100);
  color: var(--color--neutral--800);
}

.bg-cyan-150 {
  background-color: var(--color--cyan--150);
  color: var(--color--neutral--800);
}

.bg-cyan-200 {
  background-color: var(--color--cyan--200);
  color: var(--color--neutral--800);
}

.bg-cyan-300 {
  background-color: var(--color--cyan--300);
  color: var(--color--neutral--800);
}

.bg-cyan-400 {
  background-color: var(--color--cyan--400);
  color: var(--color--neutral--800);
}

.bg-cyan-500 {
  background-color: var(--color--cyan--500);
  color: var(--color--neutral--100);
}

.bg-cyan-600 {
  background-color: var(--color--cyan--600);
  color: var(--color--neutral--100);
}

.bg-cyan-700 {
  background-color: var(--color--cyan--700);
  color: var(--color--neutral--100);
}

.bg-cyan-800 {
  background-color: var(--color--cyan--800);
  color: var(--color--neutral--100);
}

.bg-cyan-900 {
  background-color: var(--color--cyan--900);
  color: var(--color--neutral--100);
}

.bg-gray-25 {
  background-color: var(--color--gray--25);
  color: var(--color--neutral--800);
}

.bg-gray-50 {
  background-color: var(--color--gray--50);
  color: var(--color--neutral--800);
}

.bg-gray-75 {
  background-color: var(--color--gray--75);
  color: var(--color--neutral--800);
}

.bg-gray-100 {
  background-color: var(--color--gray--100);
  color: var(--color--neutral--800);
}

.bg-gray-150 {
  background-color: var(--color--gray--150);
  color: var(--color--neutral--800);
}

.bg-gray-200 {
  background-color: var(--color--gray--200);
  color: var(--color--neutral--800);
}

.bg-gray-300 {
  background-color: var(--color--gray--300);
  color: var(--color--neutral--800);
}

.bg-gray-400 {
  background-color: var(--color--gray--400);
  color: var(--color--neutral--800);
}

.bg-gray-500 {
  background-color: var(--color--gray--500);
  color: var(--color--neutral--100);
}

.bg-gray-600 {
  background-color: var(--color--gray--600);
  color: var(--color--neutral--100);
}

.bg-gray-700 {
  background-color: var(--color--gray--700);
  color: var(--color--neutral--100);
}

.bg-gray-800 {
  background-color: var(--color--gray--800);
  color: var(--color--neutral--100);
}

.bg-gray-900 {
  background-color: var(--color--gray--900);
  color: var(--color--neutral--100);
}

.bg-indigo-25 {
  background-color: var(--color--indigo--25);
  color: var(--color--neutral--800);
}

.bg-indigo-50 {
  background-color: var(--color--indigo--50);
  color: var(--color--neutral--800);
}

.bg-indigo-75 {
  background-color: var(--color--indigo--75);
  color: var(--color--neutral--800);
}

.bg-indigo-100 {
  background-color: var(--color--indigo--100);
  color: var(--color--neutral--800);
}

.bg-indigo-150 {
  background-color: var(--color--indigo--150);
  color: var(--color--neutral--800);
}

.bg-indigo-200 {
  background-color: var(--color--indigo--200);
  color: var(--color--neutral--800);
}

.bg-indigo-300 {
  background-color: var(--color--indigo--300);
  color: var(--color--neutral--800);
}

.bg-indigo-400 {
  background-color: var(--color--indigo--400);
  color: var(--color--neutral--800);
}

.bg-indigo-500 {
  background-color: var(--color--indigo--500);
  color: var(--color--neutral--100);
}

.bg-indigo-600 {
  background-color: var(--color--indigo--600);
  color: var(--color--neutral--100);
}

.bg-indigo-700 {
  background-color: var(--color--indigo--700);
  color: var(--color--neutral--100);
}

.bg-indigo-800 {
  background-color: var(--color--indigo--800);
  color: var(--color--neutral--100);
}

.bg-indigo-900 {
  background-color: var(--color--indigo--900);
  color: var(--color--neutral--100);
}

.bg-indigo-vivid-25 {
  background-color: var(--color--indigo-vivid--25);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-50 {
  background-color: var(--color--indigo-vivid--50);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-75 {
  background-color: var(--color--indigo-vivid--75);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-100 {
  background-color: var(--color--indigo-vivid--100);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-150 {
  background-color: var(--color--indigo-vivid--150);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-200 {
  background-color: var(--color--indigo-vivid--200);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-300 {
  background-color: var(--color--indigo-vivid--300);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-400 {
  background-color: var(--color--indigo-vivid--400);
  color: var(--color--neutral--800);
}

.bg-indigo-vivid-500 {
  background-color: var(--color--indigo-vivid--500);
  color: var(--color--neutral--100);
}

.bg-indigo-vivid-600 {
  background-color: var(--color--indigo-vivid--600);
  color: var(--color--neutral--100);
}

.bg-indigo-vivid-700 {
  background-color: var(--color--indigo-vivid--700);
  color: var(--color--neutral--100);
}

.bg-indigo-vivid-800 {
  background-color: var(--color--indigo-vivid--800);
  color: var(--color--neutral--100);
}

.bg-indigo-vivid-900 {
  background-color: var(--color--indigo-vivid--900);
  color: var(--color--neutral--100);
}

.bg-red-25 {
  background-color: var(--color--red--25);
  color: var(--color--neutral--800);
}

.bg-red-50 {
  background-color: var(--color--red--50);
  color: var(--color--neutral--800);
}

.bg-red-75 {
  background-color: var(--color--red--75);
  color: var(--color--neutral--800);
}

.bg-red-100 {
  background-color: var(--color--red--100);
  color: var(--color--neutral--800);
}

.bg-red-150 {
  background-color: var(--color--red--150);
  color: var(--color--neutral--800);
}

.bg-red-200 {
  background-color: var(--color--red--200);
  color: var(--color--neutral--800);
}

.bg-red-300 {
  background-color: var(--color--red--300);
  color: var(--color--neutral--800);
}

.bg-red-400 {
  background-color: var(--color--red--400);
  color: var(--color--neutral--800);
}

.bg-red-500 {
  background-color: var(--color--red--500);
  color: var(--color--neutral--100);
}

.bg-red-600 {
  background-color: var(--color--red--600);
  color: var(--color--neutral--100);
}

.bg-red-700 {
  background-color: var(--color--red--700);
  color: var(--color--neutral--100);
}

.bg-red-800 {
  background-color: var(--color--red--800);
  color: var(--color--neutral--100);
}

.bg-red-900 {
  background-color: var(--color--red--900);
  color: var(--color--neutral--100);
}

.bg-red-cool-25 {
  background-color: var(--color--red-cool--25);
  color: var(--color--neutral--800);
}

.bg-red-cool-50 {
  background-color: var(--color--red-cool--50);
  color: var(--color--neutral--800);
}

.bg-red-cool-75 {
  background-color: var(--color--red-cool--75);
  color: var(--color--neutral--800);
}

.bg-red-cool-100 {
  background-color: var(--color--red-cool--100);
  color: var(--color--neutral--800);
}

.bg-red-cool-150 {
  background-color: var(--color--red-cool--150);
  color: var(--color--neutral--800);
}

.bg-red-cool-200 {
  background-color: var(--color--red-cool--200);
  color: var(--color--neutral--800);
}

.bg-red-cool-300 {
  background-color: var(--color--red-cool--300);
  color: var(--color--neutral--800);
}

.bg-red-cool-400 {
  background-color: var(--color--red-cool--400);
  color: var(--color--neutral--800);
}

.bg-red-cool-500 {
  background-color: var(--color--red-cool--500);
  color: var(--color--neutral--100);
}

.bg-red-cool-600 {
  background-color: var(--color--red-cool--600);
  color: var(--color--neutral--100);
}

.bg-red-cool-700 {
  background-color: var(--color--red-cool--700);
  color: var(--color--neutral--100);
}

.bg-red-cool-800 {
  background-color: var(--color--red-cool--800);
  color: var(--color--neutral--100);
}

.bg-red-cool-900 {
  background-color: var(--color--red-cool--900);
  color: var(--color--neutral--100);
}

.bg-orange-warm-25 {
  background-color: var(--color--orange-warm--25);
  color: var(--color--neutral--800);
}

.bg-orange-warm-50 {
  background-color: var(--color--orange-warm--50);
  color: var(--color--neutral--800);
}

.bg-orange-warm-75 {
  background-color: var(--color--orange-warm--75);
  color: var(--color--neutral--800);
}

.bg-orange-warm-100 {
  background-color: var(--color--orange-warm--100);
  color: var(--color--neutral--800);
}

.bg-orange-warm-150 {
  background-color: var(--color--orange-warm--150);
  color: var(--color--neutral--800);
}

.bg-orange-warm-200 {
  background-color: var(--color--orange-warm--200);
  color: var(--color--neutral--800);
}

.bg-orange-warm-300 {
  background-color: var(--color--orange-warm--300);
  color: var(--color--neutral--800);
}

.bg-orange-warm-400 {
  background-color: var(--color--orange-warm--400);
  color: var(--color--neutral--800);
}

.bg-orange-warm-500 {
  background-color: var(--color--orange-warm--500);
  color: var(--color--neutral--100);
}

.bg-orange-warm-600 {
  background-color: var(--color--orange-warm--600);
  color: var(--color--neutral--100);
}

.bg-orange-warm-700 {
  background-color: var(--color--orange-warm--700);
  color: var(--color--neutral--100);
}

.bg-orange-warm-800 {
  background-color: var(--color--orange-warm--800);
  color: var(--color--neutral--100);
}

.bg-orange-warm-900 {
  background-color: var(--color--orange-warm--900);
  color: var(--color--neutral--100);
}

.bg-green-25 {
  background-color: var(--color--green--25);
  color: var(--color--neutral--800);
}

.bg-green-50 {
  background-color: var(--color--green--50);
  color: var(--color--neutral--800);
}

.bg-green-75 {
  background-color: var(--color--green--75);
  color: var(--color--neutral--800);
}

.bg-green-100 {
  background-color: var(--color--green--100);
  color: var(--color--neutral--800);
}

.bg-green-150 {
  background-color: var(--color--green--150);
  color: var(--color--neutral--800);
}

.bg-green-200 {
  background-color: var(--color--green--200);
  color: var(--color--neutral--800);
}

.bg-green-300 {
  background-color: var(--color--green--300);
  color: var(--color--neutral--800);
}

.bg-green-400 {
  background-color: var(--color--green--400);
  color: var(--color--neutral--800);
}

.bg-green-500 {
  background-color: var(--color--green--500);
  color: var(--color--neutral--100);
}

.bg-green-600 {
  background-color: var(--color--green--600);
  color: var(--color--neutral--100);
}

.bg-green-700 {
  background-color: var(--color--green--700);
  color: var(--color--neutral--100);
}

.bg-green-800 {
  background-color: var(--color--green--800);
  color: var(--color--neutral--100);
}

.bg-green-900 {
  background-color: var(--color--green--900);
  color: var(--color--neutral--100);
}

.bg-blue-25 {
  background-color: var(--color--blue--25);
  color: var(--color--neutral--800);
}

.bg-blue-50 {
  background-color: var(--color--blue--50);
  color: var(--color--neutral--800);
}

.bg-blue-75 {
  background-color: var(--color--blue--75);
  color: var(--color--neutral--800);
}

.bg-blue-100 {
  background-color: var(--color--blue--100);
  color: var(--color--neutral--800);
}

.bg-blue-150 {
  background-color: var(--color--blue--150);
  color: var(--color--neutral--800);
}

.bg-blue-200 {
  background-color: var(--color--blue--200);
  color: var(--color--neutral--800);
}

.bg-blue-300 {
  background-color: var(--color--blue--300);
  color: var(--color--neutral--800);
}

.bg-blue-400 {
  background-color: var(--color--blue--400);
  color: var(--color--neutral--800);
}

.bg-blue-500 {
  background-color: var(--color--blue--500);
  color: var(--color--neutral--100);
}

.bg-blue-600 {
  background-color: var(--color--blue--600);
  color: var(--color--neutral--100);
}

.bg-blue-700 {
  background-color: var(--color--blue--700);
  color: var(--color--neutral--100);
}

.bg-blue-800 {
  background-color: var(--color--blue--800);
  color: var(--color--neutral--100);
}

.bg-blue-900 {
  background-color: var(--color--blue--900);
  color: var(--color--neutral--100);
}

.bg-blue-warm-25 {
  background-color: var(--color--blue-warm--25);
  color: var(--color--neutral--800);
}

.bg-blue-warm-50 {
  background-color: var(--color--blue-warm--50);
  color: var(--color--neutral--800);
}

.bg-blue-warm-75 {
  background-color: var(--color--blue-warm--75);
  color: var(--color--neutral--800);
}

.bg-blue-warm-100 {
  background-color: var(--color--blue-warm--100);
  color: var(--color--neutral--800);
}

.bg-blue-warm-150 {
  background-color: var(--color--blue-warm--150);
  color: var(--color--neutral--800);
}

.bg-blue-warm-200 {
  background-color: var(--color--blue-warm--200);
  color: var(--color--neutral--800);
}

.bg-blue-warm-300 {
  background-color: var(--color--blue-warm--300);
  color: var(--color--neutral--800);
}

.bg-blue-warm-400 {
  background-color: var(--color--blue-warm--400);
  color: var(--color--neutral--800);
}

.bg-blue-warm-500 {
  background-color: var(--color--blue-warm--500);
  color: var(--color--neutral--100);
}

.bg-blue-warm-600 {
  background-color: var(--color--blue-warm--600);
  color: var(--color--neutral--100);
}

.bg-blue-warm-700 {
  background-color: var(--color--blue-warm--700);
  color: var(--color--neutral--100);
}

.bg-blue-warm-800 {
  background-color: var(--color--blue-warm--800);
  color: var(--color--neutral--100);
}

.bg-blue-warm-900 {
  background-color: var(--color--blue-warm--900);
  color: var(--color--neutral--100);
}

.bg-violet-warm-25 {
  background-color: var(--color--violet-warm--25);
  color: var(--color--neutral--800);
}

.bg-violet-warm-50 {
  background-color: var(--color--violet-warm--50);
  color: var(--color--neutral--800);
}

.bg-violet-warm-75 {
  background-color: var(--color--violet-warm--75);
  color: var(--color--neutral--800);
}

.bg-violet-warm-100 {
  background-color: var(--color--violet-warm--100);
  color: var(--color--neutral--800);
}

.bg-violet-warm-150 {
  background-color: var(--color--violet-warm--150);
  color: var(--color--neutral--800);
}

.bg-violet-warm-200 {
  background-color: var(--color--violet-warm--200);
  color: var(--color--neutral--800);
}

.bg-violet-warm-300 {
  background-color: var(--color--violet-warm--300);
  color: var(--color--neutral--800);
}

.bg-violet-warm-400 {
  background-color: var(--color--violet-warm--400);
  color: var(--color--neutral--800);
}

.bg-violet-warm-500 {
  background-color: var(--color--violet-warm--500);
  color: var(--color--neutral--100);
}

.bg-violet-warm-600 {
  background-color: var(--color--violet-warm--600);
  color: var(--color--neutral--100);
}

.bg-violet-warm-700 {
  background-color: var(--color--violet-warm--700);
  color: var(--color--neutral--100);
}

.bg-violet-warm-800 {
  background-color: var(--color--violet-warm--800);
  color: var(--color--neutral--100);
}

.bg-violet-warm-900 {
  background-color: var(--color--violet-warm--900);
  color: var(--color--neutral--100);
}

.bg-magenta-25 {
  background-color: var(--color--magenta--25);
  color: var(--color--neutral--800);
}

.bg-magenta-50 {
  background-color: var(--color--magenta--50);
  color: var(--color--neutral--800);
}

.bg-magenta-75 {
  background-color: var(--color--magenta--75);
  color: var(--color--neutral--800);
}

.bg-magenta-100 {
  background-color: var(--color--magenta--100);
  color: var(--color--neutral--800);
}

.bg-magenta-150 {
  background-color: var(--color--magenta--150);
  color: var(--color--neutral--800);
}

.bg-magenta-200 {
  background-color: var(--color--magenta--200);
  color: var(--color--neutral--800);
}

.bg-magenta-300 {
  background-color: var(--color--magenta--300);
  color: var(--color--neutral--800);
}

.bg-magenta-400 {
  background-color: var(--color--magenta--400);
  color: var(--color--neutral--800);
}

.bg-magenta-500 {
  background-color: var(--color--magenta--500);
  color: var(--color--neutral--100);
}

.bg-magenta-600 {
  background-color: var(--color--magenta--600);
  color: var(--color--neutral--100);
}

.bg-magenta-700 {
  background-color: var(--color--magenta--700);
  color: var(--color--neutral--100);
}

.bg-magenta-800 {
  background-color: var(--color--magenta--800);
  color: var(--color--neutral--100);
}

.bg-magenta-900 {
  background-color: var(--color--magenta--900);
  color: var(--color--neutral--100);
}

.bg-orange-25 {
  background-color: var(--color--orange--25);
  color: var(--color--neutral--800);
}

.bg-orange-50 {
  background-color: var(--color--orange--50);
  color: var(--color--neutral--800);
}

.bg-orange-75 {
  background-color: var(--color--orange--75);
  color: var(--color--neutral--800);
}

.bg-orange-100 {
  background-color: var(--color--orange--100);
  color: var(--color--neutral--800);
}

.bg-orange-150 {
  background-color: var(--color--orange--150);
  color: var(--color--neutral--800);
}

.bg-orange-200 {
  background-color: var(--color--orange--200);
  color: var(--color--neutral--800);
}

.bg-orange-300 {
  background-color: var(--color--orange--300);
  color: var(--color--neutral--800);
}

.bg-orange-400 {
  background-color: var(--color--orange--400);
  color: var(--color--neutral--800);
}

.bg-orange-500 {
  background-color: var(--color--orange--500);
  color: var(--color--neutral--100);
}

.bg-orange-600 {
  background-color: var(--color--orange--600);
  color: var(--color--neutral--100);
}

.bg-orange-700 {
  background-color: var(--color--orange--700);
  color: var(--color--neutral--100);
}

.bg-orange-800 {
  background-color: var(--color--orange--800);
  color: var(--color--neutral--100);
}

.bg-orange-900 {
  background-color: var(--color--orange--900);
  color: var(--color--neutral--100);
}

.bg-brand-25, .bg-brand-subtle, .hover\:bg-brand-subtle:hover {
  background-color: var(--color--brand--25);
  color: var(--color--neutral--800);
}

.bg-brand-50 {
  background-color: var(--color--brand--50);
  color: var(--color--neutral--800);
}

.bg-brand-75 {
  background-color: var(--color--brand--75);
  color: var(--color--neutral--800);
}

.bg-brand-100 {
  background-color: var(--color--brand--100);
  color: var(--color--neutral--800);
}

.bg-brand-150, .dremio-dark .dremio-button--primary:hover:not([aria-busy=true]), .dremio-dark .dremio-button--primary:focus-visible:not([aria-busy=true]) {
  background-color: var(--color--brand--150);
  color: var(--color--neutral--800);
}

.bg-brand-200, .dremio-dark .dremio-button--primary {
  background-color: var(--color--brand--200);
  color: var(--color--neutral--800);
}

.bg-brand-300, .dremio-button--primary, .bg-brand-bold {
  background-color: var(--color--brand--300);
  color: var(--color--neutral--800);
}

.bg-brand-400, .dremio-button--primary:hover:not([aria-busy=true]), .dremio-button--primary:focus-visible:not([aria-busy=true]) {
  background-color: var(--color--brand--400);
  color: var(--color--neutral--800);
}

.bg-brand-500 {
  background-color: var(--color--brand--500);
  color: var(--color--neutral--100);
}

.bg-brand-600, .dremio-light .bg-brand-500 .dremio-button--primary,
.dremio-dark .bg-brand-500 .dremio-button--primary {
  background-color: var(--color--brand--600);
  color: var(--color--neutral--100);
}

.bg-brand-700, .dremio-light .bg-brand-500 .dremio-button--primary:hover,
.dremio-dark .bg-brand-500 .dremio-button--primary:hover {
  background-color: var(--color--brand--700);
  color: var(--color--neutral--100);
}

.bg-brand-800 {
  background-color: var(--color--brand--800);
  color: var(--color--neutral--100);
}

.bg-brand-900 {
  background-color: var(--color--brand--900);
  color: var(--color--neutral--100);
}

.bg-neutral-25 {
  background-color: var(--color--neutral--25);
  color: var(--color--neutral--800);
}

.bg-neutral-50 {
  background-color: var(--color--neutral--50);
  color: var(--color--neutral--800);
}

.bg-neutral-75 {
  background-color: var(--color--neutral--75);
  color: var(--color--neutral--800);
}

.bg-neutral-100 {
  background-color: var(--color--neutral--100);
  color: var(--color--neutral--800);
}

.bg-neutral-150 {
  background-color: var(--color--neutral--150);
  color: var(--color--neutral--800);
}

.bg-neutral-200 {
  background-color: var(--color--neutral--200);
  color: var(--color--neutral--800);
}

.bg-neutral-300 {
  background-color: var(--color--neutral--300);
  color: var(--color--neutral--800);
}

.bg-neutral-400 {
  background-color: var(--color--neutral--400);
  color: var(--color--neutral--800);
}

.bg-neutral-500 {
  background-color: var(--color--neutral--500);
  color: var(--color--neutral--100);
}

.bg-neutral-600 {
  background-color: var(--color--neutral--600);
  color: var(--color--neutral--100);
}

.bg-neutral-700 {
  background-color: var(--color--neutral--700);
  color: var(--color--neutral--100);
}

.bg-neutral-800 {
  background-color: var(--color--neutral--800);
  color: var(--color--neutral--100);
}

.bg-neutral-900 {
  background-color: var(--color--neutral--900);
  color: var(--color--neutral--100);
}

.bg-mint-25 {
  background-color: var(--color--mint--25);
  color: var(--color--neutral--800);
}

.bg-mint-50 {
  background-color: var(--color--mint--50);
  color: var(--color--neutral--800);
}

.bg-mint-75 {
  background-color: var(--color--mint--75);
  color: var(--color--neutral--800);
}

.bg-mint-100 {
  background-color: var(--color--mint--100);
  color: var(--color--neutral--800);
}

.bg-mint-150 {
  background-color: var(--color--mint--150);
  color: var(--color--neutral--800);
}

.bg-mint-200 {
  background-color: var(--color--mint--200);
  color: var(--color--neutral--800);
}

.bg-mint-300 {
  background-color: var(--color--mint--300);
  color: var(--color--neutral--800);
}

.bg-mint-400 {
  background-color: var(--color--mint--400);
  color: var(--color--neutral--800);
}

.bg-mint-500 {
  background-color: var(--color--mint--500);
  color: var(--color--neutral--100);
}

.bg-mint-600 {
  background-color: var(--color--mint--600);
  color: var(--color--neutral--100);
}

.bg-mint-700 {
  background-color: var(--color--mint--700);
  color: var(--color--neutral--100);
}

.bg-mint-800 {
  background-color: var(--color--mint--800);
  color: var(--color--neutral--100);
}

.bg-mint-900 {
  background-color: var(--color--mint--900);
  color: var(--color--neutral--100);
}

.bg-brand-25, .bg-brand-subtle, .hover\:bg-brand-subtle:hover {
  background-color: var(--color--cyan--25);
  color: var(--color--neutral--800);
}

.bg-brand-50 {
  background-color: var(--color--cyan--50);
  color: var(--color--neutral--800);
}

.bg-brand-75 {
  background-color: var(--color--cyan--75);
  color: var(--color--neutral--800);
}

.bg-brand-100 {
  background-color: var(--color--cyan--100);
  color: var(--color--neutral--800);
}

.bg-brand-150, .dremio-dark .dremio-button--primary:hover:not([aria-busy=true]), .dremio-dark .dremio-button--primary:focus-visible:not([aria-busy=true]) {
  background-color: var(--color--cyan--150);
  color: var(--color--neutral--800);
}

.bg-brand-200, .dremio-dark .dremio-button--primary {
  background-color: var(--color--cyan--200);
  color: var(--color--neutral--800);
}

.bg-brand-300, .dremio-button--primary, .bg-brand-bold {
  background-color: var(--color--cyan--300);
  color: var(--color--neutral--800);
}

.bg-brand-400, .dremio-button--primary:hover:not([aria-busy=true]), .dremio-button--primary:focus-visible:not([aria-busy=true]) {
  background-color: var(--color--cyan--400);
  color: var(--color--neutral--800);
}

.bg-brand-500 {
  background-color: var(--color--cyan--500);
  color: var(--color--neutral--100);
}

.bg-brand-600, .dremio-light .bg-brand-500 .dremio-button--primary,
.dremio-dark .bg-brand-500 .dremio-button--primary {
  background-color: var(--color--cyan--600);
  color: var(--color--neutral--100);
}

.bg-brand-700, .dremio-light .bg-brand-500 .dremio-button--primary:hover,
.dremio-dark .bg-brand-500 .dremio-button--primary:hover {
  background-color: var(--color--cyan--700);
  color: var(--color--neutral--100);
}

.bg-brand-800 {
  background-color: var(--color--cyan--800);
  color: var(--color--neutral--100);
}

.bg-brand-900 {
  background-color: var(--color--cyan--900);
  color: var(--color--neutral--100);
}

.bg-brand-300, .dremio-button--primary, .bg-brand-bold {
  color: white;
}

.bg-brand-400, .dremio-button--primary:hover:not([aria-busy=true]), .dremio-button--primary:focus-visible:not([aria-busy=true]) {
  color: white;
}

.bg-danger-50, .bg-danger-subtle, .bg-danger-subtle-border {
  background-color: var(--fill--danger);
  color: black;
}

.bg-danger-400, .bg-danger-bold {
  background-color: var(--fill--danger--solid);
  color: white;
}

.bg-warning-50, .bg-warning-subtle {
  background-color: var(--fill--warning);
  color: black;
}

.bg-warning-200, .bg-warning-bold {
  background-color: var(--fill--warning--solid);
  color: white;
}

.bg-success-50, .bg-success-subtle {
  background-color: var(--fill--success);
  color: black;
}

.bg-success-300, .bg-success-bold {
  background-color: var(--fill--success--solid);
  color: black;
}

.bg-info-50, .bg-info-subtle {
  background-color: var(--fill--info);
  color: black;
}

.bg-info-400, .bg-info-bold {
  background-color: var(--fill--info--solid);
  color: black;
}

.bg-danger-subtle-border {
  color: var(--fill--danger--solid);
  border: 1px solid var(--fill--danger--solid);
}

.bg-none, .dremio-light .bg-brand-500 .dremio-button--tertiary,
.dremio-dark .bg-brand-500 .dremio-button--tertiary, .dremio-light .bg-brand-500 .dremio-button--tertiary:hover,
.dremio-dark .bg-brand-500 .dremio-button--tertiary:hover {
  background: none;
}

.hover\:bg-brand-subtle:hover {
  transition-property: background;
}

.bg-disabled, .form-control input:disabled, .form-control input[aria-disabled=true], .form-control:disabled, .form-control[aria-disabled=true], .dremio-button:disabled:not([aria-busy=true]), .dremio-button[aria-disabled=true]:not([aria-busy=true]) {
  background: var(--fill--disabled) !important;
  color: var(--text--disabled);
  --bg: var(--fill--disabled);
  --text--hovered: var(--text--disabled);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.border, .dremio-card, .dremio-button {
  border-width: 1px;
}

.border-none, .form-control input {
  border-style: none;
}

.border-solid, .dremio-card, .dremio-button {
  border-style: solid;
}

.border-transparent {
  border-color: transparent;
}

.border-cyan-25 {
  border-color: var(--color--cyan--25);
}

.border-cyan-50 {
  border-color: var(--color--cyan--50);
}

.border-cyan-75 {
  border-color: var(--color--cyan--75);
}

.border-cyan-100 {
  border-color: var(--color--cyan--100);
}

.border-cyan-150 {
  border-color: var(--color--cyan--150);
}

.border-cyan-200 {
  border-color: var(--color--cyan--200);
}

.border-cyan-300 {
  border-color: var(--color--cyan--300);
}

.border-cyan-400 {
  border-color: var(--color--cyan--400);
}

.border-cyan-500 {
  border-color: var(--color--cyan--500);
}

.border-cyan-600 {
  border-color: var(--color--cyan--600);
}

.border-cyan-700 {
  border-color: var(--color--cyan--700);
}

.border-cyan-800 {
  border-color: var(--color--cyan--800);
}

.border-cyan-900 {
  border-color: var(--color--cyan--900);
}

.border-gray-25 {
  border-color: var(--color--gray--25);
}

.border-gray-50 {
  border-color: var(--color--gray--50);
}

.border-gray-75 {
  border-color: var(--color--gray--75);
}

.border-gray-100 {
  border-color: var(--color--gray--100);
}

.border-gray-150 {
  border-color: var(--color--gray--150);
}

.border-gray-200 {
  border-color: var(--color--gray--200);
}

.border-gray-300 {
  border-color: var(--color--gray--300);
}

.border-gray-400 {
  border-color: var(--color--gray--400);
}

.border-gray-500 {
  border-color: var(--color--gray--500);
}

.border-gray-600 {
  border-color: var(--color--gray--600);
}

.border-gray-700 {
  border-color: var(--color--gray--700);
}

.border-gray-800 {
  border-color: var(--color--gray--800);
}

.border-gray-900 {
  border-color: var(--color--gray--900);
}

.border-indigo-25 {
  border-color: var(--color--indigo--25);
}

.border-indigo-50 {
  border-color: var(--color--indigo--50);
}

.border-indigo-75 {
  border-color: var(--color--indigo--75);
}

.border-indigo-100 {
  border-color: var(--color--indigo--100);
}

.border-indigo-150 {
  border-color: var(--color--indigo--150);
}

.border-indigo-200 {
  border-color: var(--color--indigo--200);
}

.border-indigo-300 {
  border-color: var(--color--indigo--300);
}

.border-indigo-400 {
  border-color: var(--color--indigo--400);
}

.border-indigo-500 {
  border-color: var(--color--indigo--500);
}

.border-indigo-600 {
  border-color: var(--color--indigo--600);
}

.border-indigo-700 {
  border-color: var(--color--indigo--700);
}

.border-indigo-800 {
  border-color: var(--color--indigo--800);
}

.border-indigo-900 {
  border-color: var(--color--indigo--900);
}

.border-indigo-vivid-25 {
  border-color: var(--color--indigo-vivid--25);
}

.border-indigo-vivid-50 {
  border-color: var(--color--indigo-vivid--50);
}

.border-indigo-vivid-75 {
  border-color: var(--color--indigo-vivid--75);
}

.border-indigo-vivid-100 {
  border-color: var(--color--indigo-vivid--100);
}

.border-indigo-vivid-150 {
  border-color: var(--color--indigo-vivid--150);
}

.border-indigo-vivid-200 {
  border-color: var(--color--indigo-vivid--200);
}

.border-indigo-vivid-300 {
  border-color: var(--color--indigo-vivid--300);
}

.border-indigo-vivid-400 {
  border-color: var(--color--indigo-vivid--400);
}

.border-indigo-vivid-500 {
  border-color: var(--color--indigo-vivid--500);
}

.border-indigo-vivid-600 {
  border-color: var(--color--indigo-vivid--600);
}

.border-indigo-vivid-700 {
  border-color: var(--color--indigo-vivid--700);
}

.border-indigo-vivid-800 {
  border-color: var(--color--indigo-vivid--800);
}

.border-indigo-vivid-900 {
  border-color: var(--color--indigo-vivid--900);
}

.border-red-25 {
  border-color: var(--color--red--25);
}

.border-red-50 {
  border-color: var(--color--red--50);
}

.border-red-75 {
  border-color: var(--color--red--75);
}

.border-red-100 {
  border-color: var(--color--red--100);
}

.border-red-150 {
  border-color: var(--color--red--150);
}

.border-red-200 {
  border-color: var(--color--red--200);
}

.border-red-300 {
  border-color: var(--color--red--300);
}

.border-red-400 {
  border-color: var(--color--red--400);
}

.border-red-500 {
  border-color: var(--color--red--500);
}

.border-red-600 {
  border-color: var(--color--red--600);
}

.border-red-700 {
  border-color: var(--color--red--700);
}

.border-red-800 {
  border-color: var(--color--red--800);
}

.border-red-900 {
  border-color: var(--color--red--900);
}

.border-red-cool-25 {
  border-color: var(--color--red-cool--25);
}

.border-red-cool-50 {
  border-color: var(--color--red-cool--50);
}

.border-red-cool-75 {
  border-color: var(--color--red-cool--75);
}

.border-red-cool-100 {
  border-color: var(--color--red-cool--100);
}

.border-red-cool-150 {
  border-color: var(--color--red-cool--150);
}

.border-red-cool-200 {
  border-color: var(--color--red-cool--200);
}

.border-red-cool-300 {
  border-color: var(--color--red-cool--300);
}

.border-red-cool-400 {
  border-color: var(--color--red-cool--400);
}

.border-red-cool-500 {
  border-color: var(--color--red-cool--500);
}

.border-red-cool-600 {
  border-color: var(--color--red-cool--600);
}

.border-red-cool-700 {
  border-color: var(--color--red-cool--700);
}

.border-red-cool-800 {
  border-color: var(--color--red-cool--800);
}

.border-red-cool-900 {
  border-color: var(--color--red-cool--900);
}

.border-orange-warm-25 {
  border-color: var(--color--orange-warm--25);
}

.border-orange-warm-50 {
  border-color: var(--color--orange-warm--50);
}

.border-orange-warm-75 {
  border-color: var(--color--orange-warm--75);
}

.border-orange-warm-100 {
  border-color: var(--color--orange-warm--100);
}

.border-orange-warm-150 {
  border-color: var(--color--orange-warm--150);
}

.border-orange-warm-200 {
  border-color: var(--color--orange-warm--200);
}

.border-orange-warm-300 {
  border-color: var(--color--orange-warm--300);
}

.border-orange-warm-400 {
  border-color: var(--color--orange-warm--400);
}

.border-orange-warm-500 {
  border-color: var(--color--orange-warm--500);
}

.border-orange-warm-600 {
  border-color: var(--color--orange-warm--600);
}

.border-orange-warm-700 {
  border-color: var(--color--orange-warm--700);
}

.border-orange-warm-800 {
  border-color: var(--color--orange-warm--800);
}

.border-orange-warm-900 {
  border-color: var(--color--orange-warm--900);
}

.border-green-25 {
  border-color: var(--color--green--25);
}

.border-green-50 {
  border-color: var(--color--green--50);
}

.border-green-75 {
  border-color: var(--color--green--75);
}

.border-green-100 {
  border-color: var(--color--green--100);
}

.border-green-150 {
  border-color: var(--color--green--150);
}

.border-green-200 {
  border-color: var(--color--green--200);
}

.border-green-300 {
  border-color: var(--color--green--300);
}

.border-green-400 {
  border-color: var(--color--green--400);
}

.border-green-500 {
  border-color: var(--color--green--500);
}

.border-green-600 {
  border-color: var(--color--green--600);
}

.border-green-700 {
  border-color: var(--color--green--700);
}

.border-green-800 {
  border-color: var(--color--green--800);
}

.border-green-900 {
  border-color: var(--color--green--900);
}

.border-blue-25 {
  border-color: var(--color--blue--25);
}

.border-blue-50 {
  border-color: var(--color--blue--50);
}

.border-blue-75 {
  border-color: var(--color--blue--75);
}

.border-blue-100 {
  border-color: var(--color--blue--100);
}

.border-blue-150 {
  border-color: var(--color--blue--150);
}

.border-blue-200 {
  border-color: var(--color--blue--200);
}

.border-blue-300 {
  border-color: var(--color--blue--300);
}

.border-blue-400 {
  border-color: var(--color--blue--400);
}

.border-blue-500 {
  border-color: var(--color--blue--500);
}

.border-blue-600 {
  border-color: var(--color--blue--600);
}

.border-blue-700 {
  border-color: var(--color--blue--700);
}

.border-blue-800 {
  border-color: var(--color--blue--800);
}

.border-blue-900 {
  border-color: var(--color--blue--900);
}

.border-blue-warm-25 {
  border-color: var(--color--blue-warm--25);
}

.border-blue-warm-50 {
  border-color: var(--color--blue-warm--50);
}

.border-blue-warm-75 {
  border-color: var(--color--blue-warm--75);
}

.border-blue-warm-100 {
  border-color: var(--color--blue-warm--100);
}

.border-blue-warm-150 {
  border-color: var(--color--blue-warm--150);
}

.border-blue-warm-200 {
  border-color: var(--color--blue-warm--200);
}

.border-blue-warm-300 {
  border-color: var(--color--blue-warm--300);
}

.border-blue-warm-400 {
  border-color: var(--color--blue-warm--400);
}

.border-blue-warm-500 {
  border-color: var(--color--blue-warm--500);
}

.border-blue-warm-600 {
  border-color: var(--color--blue-warm--600);
}

.border-blue-warm-700 {
  border-color: var(--color--blue-warm--700);
}

.border-blue-warm-800 {
  border-color: var(--color--blue-warm--800);
}

.border-blue-warm-900 {
  border-color: var(--color--blue-warm--900);
}

.border-violet-warm-25 {
  border-color: var(--color--violet-warm--25);
}

.border-violet-warm-50 {
  border-color: var(--color--violet-warm--50);
}

.border-violet-warm-75 {
  border-color: var(--color--violet-warm--75);
}

.border-violet-warm-100 {
  border-color: var(--color--violet-warm--100);
}

.border-violet-warm-150 {
  border-color: var(--color--violet-warm--150);
}

.border-violet-warm-200 {
  border-color: var(--color--violet-warm--200);
}

.border-violet-warm-300 {
  border-color: var(--color--violet-warm--300);
}

.border-violet-warm-400 {
  border-color: var(--color--violet-warm--400);
}

.border-violet-warm-500 {
  border-color: var(--color--violet-warm--500);
}

.border-violet-warm-600 {
  border-color: var(--color--violet-warm--600);
}

.border-violet-warm-700 {
  border-color: var(--color--violet-warm--700);
}

.border-violet-warm-800 {
  border-color: var(--color--violet-warm--800);
}

.border-violet-warm-900 {
  border-color: var(--color--violet-warm--900);
}

.border-magenta-25 {
  border-color: var(--color--magenta--25);
}

.border-magenta-50 {
  border-color: var(--color--magenta--50);
}

.border-magenta-75 {
  border-color: var(--color--magenta--75);
}

.border-magenta-100 {
  border-color: var(--color--magenta--100);
}

.border-magenta-150 {
  border-color: var(--color--magenta--150);
}

.border-magenta-200 {
  border-color: var(--color--magenta--200);
}

.border-magenta-300 {
  border-color: var(--color--magenta--300);
}

.border-magenta-400 {
  border-color: var(--color--magenta--400);
}

.border-magenta-500 {
  border-color: var(--color--magenta--500);
}

.border-magenta-600 {
  border-color: var(--color--magenta--600);
}

.border-magenta-700 {
  border-color: var(--color--magenta--700);
}

.border-magenta-800 {
  border-color: var(--color--magenta--800);
}

.border-magenta-900 {
  border-color: var(--color--magenta--900);
}

.border-orange-25 {
  border-color: var(--color--orange--25);
}

.border-orange-50 {
  border-color: var(--color--orange--50);
}

.border-orange-75 {
  border-color: var(--color--orange--75);
}

.border-orange-100 {
  border-color: var(--color--orange--100);
}

.border-orange-150 {
  border-color: var(--color--orange--150);
}

.border-orange-200 {
  border-color: var(--color--orange--200);
}

.border-orange-300 {
  border-color: var(--color--orange--300);
}

.border-orange-400 {
  border-color: var(--color--orange--400);
}

.border-orange-500 {
  border-color: var(--color--orange--500);
}

.border-orange-600 {
  border-color: var(--color--orange--600);
}

.border-orange-700 {
  border-color: var(--color--orange--700);
}

.border-orange-800 {
  border-color: var(--color--orange--800);
}

.border-orange-900 {
  border-color: var(--color--orange--900);
}

.border-brand-25 {
  border-color: var(--color--brand--25);
}

.border-brand-50 {
  border-color: var(--color--brand--50);
}

.border-brand-75 {
  border-color: var(--color--brand--75);
}

.border-brand-100 {
  border-color: var(--color--brand--100);
}

.border-brand-150 {
  border-color: var(--color--brand--150);
}

.border-brand-200 {
  border-color: var(--color--brand--200);
}

.border-brand-300 {
  border-color: var(--color--brand--300);
}

.border-brand-400 {
  border-color: var(--color--brand--400);
}

.border-brand-500 {
  border-color: var(--color--brand--500);
}

.border-brand-600 {
  border-color: var(--color--brand--600);
}

.border-brand-700 {
  border-color: var(--color--brand--700);
}

.border-brand-800 {
  border-color: var(--color--brand--800);
}

.border-brand-900 {
  border-color: var(--color--brand--900);
}

.border-neutral-25 {
  border-color: var(--color--neutral--25);
}

.border-neutral-50 {
  border-color: var(--color--neutral--50);
}

.border-neutral-75 {
  border-color: var(--color--neutral--75);
}

.border-neutral-100 {
  border-color: var(--color--neutral--100);
}

.border-neutral-150 {
  border-color: var(--color--neutral--150);
}

.border-neutral-200 {
  border-color: var(--color--neutral--200);
}

.border-neutral-300 {
  border-color: var(--color--neutral--300);
}

.border-neutral-400 {
  border-color: var(--color--neutral--400);
}

.border-neutral-500 {
  border-color: var(--color--neutral--500);
}

.border-neutral-600 {
  border-color: var(--color--neutral--600);
}

.border-neutral-700 {
  border-color: var(--color--neutral--700);
}

.border-neutral-800 {
  border-color: var(--color--neutral--800);
}

.border-neutral-900 {
  border-color: var(--color--neutral--900);
}

.border-mint-25 {
  border-color: var(--color--mint--25);
}

.border-mint-50 {
  border-color: var(--color--mint--50);
}

.border-mint-75 {
  border-color: var(--color--mint--75);
}

.border-mint-100 {
  border-color: var(--color--mint--100);
}

.border-mint-150 {
  border-color: var(--color--mint--150);
}

.border-mint-200 {
  border-color: var(--color--mint--200);
}

.border-mint-300 {
  border-color: var(--color--mint--300);
}

.border-mint-400 {
  border-color: var(--color--mint--400);
}

.border-mint-500 {
  border-color: var(--color--mint--500);
}

.border-mint-600 {
  border-color: var(--color--mint--600);
}

.border-mint-700 {
  border-color: var(--color--mint--700);
}

.border-mint-800 {
  border-color: var(--color--mint--800);
}

.border-mint-900 {
  border-color: var(--color--mint--900);
}

.border-foreground {
  border-color: var(--border--neutral);
}

.border-danger-bold {
  border-color: var(--fill--danger--solid);
}

.dremio-dark .border-foreground {
  border-color: var(--color--neutral--500);
}

/**
 * New theming classes and variables below
 */
.border-thin {
  border: 1px solid var(--border--color);
}

.color-faded {
  color: var(--text--faded);
}

.color-neutral-300 {
  color: var(--color--neutral--300);
}

.color-neutral-900 {
  color: var(--color--neutral--900);
}

.color-info-300 {
  color: var(--color--brand--200);
}

.color-info-400 {
  color: var(--color--cyan--400);
}

.color-success-300 {
  color: var(--color--green--300);
}

.color-danger-400 {
  color: var(--fill--danger--solid);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.cursor-pointer {
  cursor: pointer;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.self-start {
  align-self: start;
}

.self-end {
  align-self: end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-center, .form-control, .listbox-item, .dremio-button, .dremio-button__content {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center, .dremio-button__content {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.min-width-0 {
  min-width: 0;
}

.flex-1, .form-control input, .dremio-button__content {
  flex: 1;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row, .form-control, .dremio-button, .dremio-button__content {
  flex-direction: row;
}

.flex-col, .listbox,
.listbox-group {
  flex-direction: column;
}

.flex, .form-control, .listbox,
.listbox-group, .listbox-item, .dremio-avatar-group {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1 !important;
}

.shrink-0, .dremio-button__content > * {
  flex-shrink: 0;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.gap-05, .dremio-tag, .dremio-button__content {
  gap: var(--scale-05);
}

.gap-075 {
  gap: var(--scale-075);
}

.gap-1 {
  gap: var(--scale-1);
}

.gap-105 {
  gap: var(--scale-105);
}

.gap-2 {
  gap: var(--scale-2);
}

.gap-205 {
  gap: var(--scale-205);
}

.gap-3 {
  gap: var(--scale-3);
}

.gap-4 {
  gap: var(--scale-4);
}

.gap-405 {
  gap: var(--scale-405);
}

.gap-5 {
  gap: var(--scale-5);
}

.gap-505 {
  gap: var(--scale-505);
}

.gap-6 {
  gap: var(--scale-6);
}

.gap-7 {
  gap: var(--scale-7);
}

.gap-8 {
  gap: var(--scale-8);
}

.gap-9 {
  gap: var(--scale-9);
}

.gap-905 {
  gap: var(--scale-905);
}

.gap-10 {
  gap: var(--scale-10);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.h-full, .form-control input {
  block-size: 100%;
}

.h-auto {
  block-size: auto;
}

.h-max-content {
  block-size: max-content;
}

.h-05 {
  block-size: var(--scale-05);
}

.h-075 {
  block-size: var(--scale-075);
}

.h-1 {
  block-size: var(--scale-1);
}

.h-105 {
  block-size: var(--scale-105);
}

.h-2 {
  block-size: var(--scale-2);
}

.h-205 {
  block-size: var(--scale-205);
}

.h-3 {
  block-size: var(--scale-3);
}

.h-4, .form-control, .dremio-button {
  block-size: var(--scale-4);
}

.h-405 {
  block-size: var(--scale-405);
}

.h-5 {
  block-size: var(--scale-5);
}

.h-505 {
  block-size: var(--scale-505);
}

.h-6 {
  block-size: var(--scale-6);
}

.h-7 {
  block-size: var(--scale-7);
}

.h-8, .tabpanel-tablist button {
  block-size: var(--scale-8);
}

.h-9 {
  block-size: var(--scale-9);
}

.h-905 {
  block-size: var(--scale-905);
}

.h-10 {
  block-size: var(--scale-10);
}

.leading-normal {
  line-height: 1.4;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.ml-auto {
  margin-inline-start: auto;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-05 {
  margin: var(--scale-05);
}

.m-075 {
  margin: var(--scale-075);
}

.m-1 {
  margin: var(--scale-1);
}

.m-105 {
  margin: var(--scale-105);
}

.m-2 {
  margin: var(--scale-2);
}

.m-205 {
  margin: var(--scale-205);
}

.m-3 {
  margin: var(--scale-3);
}

.m-4 {
  margin: var(--scale-4);
}

.m-405 {
  margin: var(--scale-405);
}

.m-5 {
  margin: var(--scale-5);
}

.m-505 {
  margin: var(--scale-505);
}

.m-6 {
  margin: var(--scale-6);
}

.m-7 {
  margin: var(--scale-7);
}

.m-8 {
  margin: var(--scale-8);
}

.m-9 {
  margin: var(--scale-9);
}

.m-905 {
  margin: var(--scale-905);
}

.m-10 {
  margin: var(--scale-10);
}

.mt-05 {
  margin-block-start: var(--scale-05);
}

.mr-05 {
  margin-inline-end: var(--scale-05);
}

.mb-05 {
  margin-block-end: var(--scale-05);
}

.ml-05 {
  margin-inline-start: var(--scale-05);
}

.mx-05 {
  margin-inline: var(--scale-05);
}

.my-05 {
  margin-block: var(--scale-05);
}

.mt-075 {
  margin-block-start: var(--scale-075);
}

.mr-075 {
  margin-inline-end: var(--scale-075);
}

.mb-075 {
  margin-block-end: var(--scale-075);
}

.ml-075 {
  margin-inline-start: var(--scale-075);
}

.mx-075 {
  margin-inline: var(--scale-075);
}

.my-075 {
  margin-block: var(--scale-075);
}

.mt-1 {
  margin-block-start: var(--scale-1);
}

.mr-1 {
  margin-inline-end: var(--scale-1);
}

.mb-1 {
  margin-block-end: var(--scale-1);
}

.ml-1 {
  margin-inline-start: var(--scale-1);
}

.mx-1 {
  margin-inline: var(--scale-1);
}

.my-1 {
  margin-block: var(--scale-1);
}

.mt-105 {
  margin-block-start: var(--scale-105);
}

.mr-105 {
  margin-inline-end: var(--scale-105);
}

.mb-105 {
  margin-block-end: var(--scale-105);
}

.ml-105 {
  margin-inline-start: var(--scale-105);
}

.mx-105 {
  margin-inline: var(--scale-105);
}

.my-105 {
  margin-block: var(--scale-105);
}

.mt-2 {
  margin-block-start: var(--scale-2);
}

.mr-2 {
  margin-inline-end: var(--scale-2);
}

.mb-2 {
  margin-block-end: var(--scale-2);
}

.ml-2 {
  margin-inline-start: var(--scale-2);
}

.mx-2 {
  margin-inline: var(--scale-2);
}

.my-2 {
  margin-block: var(--scale-2);
}

.mt-205 {
  margin-block-start: var(--scale-205);
}

.mr-205 {
  margin-inline-end: var(--scale-205);
}

.mb-205 {
  margin-block-end: var(--scale-205);
}

.ml-205 {
  margin-inline-start: var(--scale-205);
}

.mx-205 {
  margin-inline: var(--scale-205);
}

.my-205 {
  margin-block: var(--scale-205);
}

.mt-3 {
  margin-block-start: var(--scale-3);
}

.mr-3 {
  margin-inline-end: var(--scale-3);
}

.mb-3 {
  margin-block-end: var(--scale-3);
}

.ml-3 {
  margin-inline-start: var(--scale-3);
}

.mx-3 {
  margin-inline: var(--scale-3);
}

.my-3 {
  margin-block: var(--scale-3);
}

.mt-4 {
  margin-block-start: var(--scale-4);
}

.mr-4 {
  margin-inline-end: var(--scale-4);
}

.mb-4 {
  margin-block-end: var(--scale-4);
}

.ml-4 {
  margin-inline-start: var(--scale-4);
}

.mx-4 {
  margin-inline: var(--scale-4);
}

.my-4 {
  margin-block: var(--scale-4);
}

.mt-405 {
  margin-block-start: var(--scale-405);
}

.mr-405 {
  margin-inline-end: var(--scale-405);
}

.mb-405 {
  margin-block-end: var(--scale-405);
}

.ml-405 {
  margin-inline-start: var(--scale-405);
}

.mx-405 {
  margin-inline: var(--scale-405);
}

.my-405 {
  margin-block: var(--scale-405);
}

.mt-5 {
  margin-block-start: var(--scale-5);
}

.mr-5 {
  margin-inline-end: var(--scale-5);
}

.mb-5 {
  margin-block-end: var(--scale-5);
}

.ml-5 {
  margin-inline-start: var(--scale-5);
}

.mx-5 {
  margin-inline: var(--scale-5);
}

.my-5 {
  margin-block: var(--scale-5);
}

.mt-505 {
  margin-block-start: var(--scale-505);
}

.mr-505 {
  margin-inline-end: var(--scale-505);
}

.mb-505 {
  margin-block-end: var(--scale-505);
}

.ml-505 {
  margin-inline-start: var(--scale-505);
}

.mx-505 {
  margin-inline: var(--scale-505);
}

.my-505 {
  margin-block: var(--scale-505);
}

.mt-6 {
  margin-block-start: var(--scale-6);
}

.mr-6 {
  margin-inline-end: var(--scale-6);
}

.mb-6 {
  margin-block-end: var(--scale-6);
}

.ml-6 {
  margin-inline-start: var(--scale-6);
}

.mx-6 {
  margin-inline: var(--scale-6);
}

.my-6 {
  margin-block: var(--scale-6);
}

.mt-7 {
  margin-block-start: var(--scale-7);
}

.mr-7 {
  margin-inline-end: var(--scale-7);
}

.mb-7 {
  margin-block-end: var(--scale-7);
}

.ml-7 {
  margin-inline-start: var(--scale-7);
}

.mx-7 {
  margin-inline: var(--scale-7);
}

.my-7 {
  margin-block: var(--scale-7);
}

.mt-8 {
  margin-block-start: var(--scale-8);
}

.mr-8 {
  margin-inline-end: var(--scale-8);
}

.mb-8 {
  margin-block-end: var(--scale-8);
}

.ml-8 {
  margin-inline-start: var(--scale-8);
}

.mx-8 {
  margin-inline: var(--scale-8);
}

.my-8 {
  margin-block: var(--scale-8);
}

.mt-9 {
  margin-block-start: var(--scale-9);
}

.mr-9 {
  margin-inline-end: var(--scale-9);
}

.mb-9 {
  margin-block-end: var(--scale-9);
}

.ml-9 {
  margin-inline-start: var(--scale-9);
}

.mx-9 {
  margin-inline: var(--scale-9);
}

.my-9 {
  margin-block: var(--scale-9);
}

.mt-905 {
  margin-block-start: var(--scale-905);
}

.mr-905 {
  margin-inline-end: var(--scale-905);
}

.mb-905 {
  margin-block-end: var(--scale-905);
}

.ml-905 {
  margin-inline-start: var(--scale-905);
}

.mx-905 {
  margin-inline: var(--scale-905);
}

.my-905 {
  margin-block: var(--scale-905);
}

.mt-10 {
  margin-block-start: var(--scale-10);
}

.mr-10 {
  margin-inline-end: var(--scale-10);
}

.mb-10 {
  margin-block-end: var(--scale-10);
}

.ml-10 {
  margin-inline-start: var(--scale-10);
}

.mx-10 {
  margin-inline: var(--scale-10);
}

.my-10 {
  margin-block: var(--scale-10);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden, .form-control, .dremio-button, .dremio-button__content {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.p-0 {
  padding: 0;
}

.p-05, .form-control {
  padding: var(--scale-05);
}

.p-075 {
  padding: var(--scale-075);
}

.p-1, .listbox-item {
  padding: var(--scale-1);
}

.p-105, .code-view__header, .code-view__content {
  padding: var(--scale-105);
}

.p-2, .dremio-card > * {
  padding: var(--scale-2);
}

.p-205 {
  padding: var(--scale-205);
}

.p-3 {
  padding: var(--scale-3);
}

.p-4 {
  padding: var(--scale-4);
}

.p-405 {
  padding: var(--scale-405);
}

.p-5 {
  padding: var(--scale-5);
}

.p-505 {
  padding: var(--scale-505);
}

.p-6 {
  padding: var(--scale-6);
}

.p-7 {
  padding: var(--scale-7);
}

.p-8 {
  padding: var(--scale-8);
}

.p-9 {
  padding: var(--scale-9);
}

.p-905 {
  padding: var(--scale-905);
}

.p-10 {
  padding: var(--scale-10);
}

.pt-05 {
  padding-block-start: var(--scale-05);
}

.pr-05 {
  padding-inline-end: var(--scale-05);
}

.pb-05 {
  padding-block-end: var(--scale-05);
}

.pl-05 {
  padding-inline-start: var(--scale-05);
}

.px-05 {
  padding-inline: var(--scale-05);
}

.py-05 {
  padding-block: var(--scale-05);
}

.pt-075 {
  padding-block-start: var(--scale-075);
}

.pr-075 {
  padding-inline-end: var(--scale-075);
}

.pb-075 {
  padding-block-end: var(--scale-075);
}

.pl-075 {
  padding-inline-start: var(--scale-075);
}

.px-075 {
  padding-inline: var(--scale-075);
}

.py-075 {
  padding-block: var(--scale-075);
}

.pt-1, .listbox-group-label {
  padding-block-start: var(--scale-1);
}

.pr-1 {
  padding-inline-end: var(--scale-1);
}

.pb-1 {
  padding-block-end: var(--scale-1);
}

.pl-1 {
  padding-inline-start: var(--scale-1);
}

.px-1, .listbox-group-label {
  padding-inline: var(--scale-1);
}

.py-1 {
  padding-block: var(--scale-1);
}

.pt-105 {
  padding-block-start: var(--scale-105);
}

.pr-105 {
  padding-inline-end: var(--scale-105);
}

.pb-105 {
  padding-block-end: var(--scale-105);
}

.pl-105 {
  padding-inline-start: var(--scale-105);
}

.px-105 {
  padding-inline: var(--scale-105);
}

.py-105 {
  padding-block: var(--scale-105);
}

.pt-2 {
  padding-block-start: var(--scale-2);
}

.pr-2 {
  padding-inline-end: var(--scale-2);
}

.pb-2 {
  padding-block-end: var(--scale-2);
}

.pl-2 {
  padding-inline-start: var(--scale-2);
}

.px-2 {
  padding-inline: var(--scale-2);
}

.py-2 {
  padding-block: var(--scale-2);
}

.pt-205 {
  padding-block-start: var(--scale-205);
}

.pr-205 {
  padding-inline-end: var(--scale-205);
}

.pb-205 {
  padding-block-end: var(--scale-205);
}

.pl-205 {
  padding-inline-start: var(--scale-205);
}

.px-205 {
  padding-inline: var(--scale-205);
}

.py-205 {
  padding-block: var(--scale-205);
}

.pt-3 {
  padding-block-start: var(--scale-3);
}

.pr-3 {
  padding-inline-end: var(--scale-3);
}

.pb-3 {
  padding-block-end: var(--scale-3);
}

.pl-3 {
  padding-inline-start: var(--scale-3);
}

.px-3 {
  padding-inline: var(--scale-3);
}

.py-3 {
  padding-block: var(--scale-3);
}

.pt-4 {
  padding-block-start: var(--scale-4);
}

.pr-4 {
  padding-inline-end: var(--scale-4);
}

.pb-4 {
  padding-block-end: var(--scale-4);
}

.pl-4 {
  padding-inline-start: var(--scale-4);
}

.px-4 {
  padding-inline: var(--scale-4);
}

.py-4 {
  padding-block: var(--scale-4);
}

.pt-405 {
  padding-block-start: var(--scale-405);
}

.pr-405 {
  padding-inline-end: var(--scale-405);
}

.pb-405 {
  padding-block-end: var(--scale-405);
}

.pl-405 {
  padding-inline-start: var(--scale-405);
}

.px-405 {
  padding-inline: var(--scale-405);
}

.py-405 {
  padding-block: var(--scale-405);
}

.pt-5 {
  padding-block-start: var(--scale-5);
}

.pr-5 {
  padding-inline-end: var(--scale-5);
}

.pb-5 {
  padding-block-end: var(--scale-5);
}

.pl-5 {
  padding-inline-start: var(--scale-5);
}

.px-5 {
  padding-inline: var(--scale-5);
}

.py-5 {
  padding-block: var(--scale-5);
}

.pt-505 {
  padding-block-start: var(--scale-505);
}

.pr-505 {
  padding-inline-end: var(--scale-505);
}

.pb-505 {
  padding-block-end: var(--scale-505);
}

.pl-505 {
  padding-inline-start: var(--scale-505);
}

.px-505 {
  padding-inline: var(--scale-505);
}

.py-505 {
  padding-block: var(--scale-505);
}

.pt-6 {
  padding-block-start: var(--scale-6);
}

.pr-6 {
  padding-inline-end: var(--scale-6);
}

.pb-6 {
  padding-block-end: var(--scale-6);
}

.pl-6 {
  padding-inline-start: var(--scale-6);
}

.px-6 {
  padding-inline: var(--scale-6);
}

.py-6 {
  padding-block: var(--scale-6);
}

.pt-7 {
  padding-block-start: var(--scale-7);
}

.pr-7 {
  padding-inline-end: var(--scale-7);
}

.pb-7 {
  padding-block-end: var(--scale-7);
}

.pl-7 {
  padding-inline-start: var(--scale-7);
}

.px-7 {
  padding-inline: var(--scale-7);
}

.py-7 {
  padding-block: var(--scale-7);
}

.pt-8 {
  padding-block-start: var(--scale-8);
}

.pr-8 {
  padding-inline-end: var(--scale-8);
}

.pb-8 {
  padding-block-end: var(--scale-8);
}

.pl-8 {
  padding-inline-start: var(--scale-8);
}

.px-8 {
  padding-inline: var(--scale-8);
}

.py-8 {
  padding-block: var(--scale-8);
}

.pt-9 {
  padding-block-start: var(--scale-9);
}

.pr-9 {
  padding-inline-end: var(--scale-9);
}

.pb-9 {
  padding-block-end: var(--scale-9);
}

.pl-9 {
  padding-inline-start: var(--scale-9);
}

.px-9 {
  padding-inline: var(--scale-9);
}

.py-9 {
  padding-block: var(--scale-9);
}

.pt-905 {
  padding-block-start: var(--scale-905);
}

.pr-905 {
  padding-inline-end: var(--scale-905);
}

.pb-905 {
  padding-block-end: var(--scale-905);
}

.pl-905 {
  padding-inline-start: var(--scale-905);
}

.px-905 {
  padding-inline: var(--scale-905);
}

.py-905 {
  padding-block: var(--scale-905);
}

.pt-10 {
  padding-block-start: var(--scale-10);
}

.pr-10 {
  padding-inline-end: var(--scale-10);
}

.pb-10 {
  padding-block-end: var(--scale-10);
}

.pl-10 {
  padding-inline-start: var(--scale-10);
}

.px-10 {
  padding-inline: var(--scale-10);
}

.py-10 {
  padding-block: var(--scale-10);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: 2px;
}

.rounded, .form-control, .float-container, .dremio-tag, .code-view, .dremio-card, .dremio-button {
  border-radius: 4px;
}

.rounded-md {
  border-radius: 6px;
}

.rounded-lg {
  border-radius: 8px;
}

.rounded-xl {
  border-radius: 12px;
}

.rounded-2xl {
  border-radius: 16px;
}

.rounded-3xl {
  border-radius: 24px;
}

.rounded-top-sm {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-bottom-sm {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.scrollbar-hidden {
  scrollbar-width: none;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.no-select, .dremio-button {
  user-select: none;
}

.font-default, :root {
  font-family: var(--font-family--default);
  font-feature-settings: "calt" on, "cv08" on;
}

.font-mono {
  font-family: var(--font-family--mono);
  font-feature-settings: "zero" on;
  font-variant-ligatures: none;
  font-weight: 400;
}

.text-sm, .listbox-group-label {
  font-size: 0.8571428571rem;
}

.text-base,
.text-md {
  font-size: 1rem;
  line-height: 1.5;
}

.text-lg {
  font-size: 1.1428571429rem;
}

.text-xl {
  font-size: 1.2857142857rem;
}

.text-light {
  font-weight: 300;
}

.text-normal, :root {
  font-weight: 400;
}

.text-medium, .listbox-group-label, .dremio-tag, .dremio-button {
  font-weight: 500;
}

.text-semibold, .code-view__header, .dremio-card__title, .dremio-card__title > * {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap, .dremio-button__content {
  white-space: nowrap;
}

.text-left {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: end;
}

.text-error {
  color: var(--fill--danger--solid);
}

.text-disabled {
  color: var(--text--disabled) !important;
}

.text-primary {
  color: var(--text--primary);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.w-0 {
  inline-size: 0;
}

.w-05 {
  inline-size: var(--scale-05);
}

.w-075 {
  inline-size: var(--scale-075);
}

.w-1 {
  inline-size: var(--scale-1);
}

.w-105 {
  inline-size: var(--scale-105);
}

.w-2 {
  inline-size: var(--scale-2);
}

.w-205 {
  inline-size: var(--scale-205);
}

.w-3 {
  inline-size: var(--scale-3);
}

.w-4 {
  inline-size: var(--scale-4);
}

.w-405 {
  inline-size: var(--scale-405);
}

.w-5 {
  inline-size: var(--scale-5);
}

.w-505 {
  inline-size: var(--scale-505);
}

.w-6 {
  inline-size: var(--scale-6);
}

.w-7 {
  inline-size: var(--scale-7);
}

.w-8 {
  inline-size: var(--scale-8);
}

.w-9 {
  inline-size: var(--scale-9);
}

.w-905 {
  inline-size: var(--scale-905);
}

.w-10 {
  inline-size: var(--scale-10);
}

.w-full, .form-control {
  inline-size: 100%;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.max-w-max {
  max-inline-size: max-content;
}

.max-w-min {
  max-inline-size: min-content;
}

.min-w-min {
  min-inline-size: min-content;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-avatar-group {
  flex-flow: row wrap;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-avatar-group > * {
  margin-inline-start: -6px;
}

.dremio-avatar-group .dremio-avatar {
  border: 2px solid var(--bg);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-avatar {
  align-items: center;
  box-sizing: content-box;
  contain: strict;
  display: inline-flex;
  flex-shrink: 0;
  float: left;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  user-select: none;
  background: var(--dremio--avatar--background);
  block-size: var(--dremio--avatar--size);
  border: var(--dremio--avatar--border);
  border-radius: var(--dremio--avatar--border-radius);
  color: var(--dremio--avatar--color);
  font-size: var(--dremio--avatar--font-size);
  font-weight: var(--dremio--avatar--font-weight);
  inline-size: var(--dremio--avatar--size);
  letter-spacing: var(--dremio--avatar--letter-spacing);
}
.dremio-avatar--image > * {
  block-size: 100%;
  inline-size: 100%;
  object-fit: contain;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-avatar {
  --dremio--avatar--background: #333;
  --dremio--avatar--border: none;
  --dremio--avatar--border-radius: 100%;
  --dremio--avatar--color: white;
  --dremio--avatar--font-size: 0.785em;
  --dremio--avatar--font-weight: 600;
  --dremio--avatar--size: 2.1875em;
}

.dremio-light .dremio-avatar--color-1 {
  --dremio--avatar--background: var(--color--red-cool--500);
  --dremio--avatar--color: white;
}
.dremio-light .dremio-avatar--color-2 {
  --dremio--avatar--background: var(--color--orange-warm--300);
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-3 {
  --dremio--avatar--background: var(--color--orange--150);
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-4 {
  --dremio--avatar--background: var(--color--blue-warm--300);
  --dremio--avatar--color: white;
}
.dremio-light .dremio-avatar--color-5 {
  --dremio--avatar--background: #63daff;
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-6 {
  --dremio--avatar--background: var(--color--violet-warm--500);
  --dremio--avatar--color: white;
}
.dremio-light .dremio-avatar--color-7 {
  --dremio--avatar--background: var(--color--magenta--300);
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-8 {
  --dremio--avatar--background: var(--color--indigo--300);
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-9 {
  --dremio--avatar--background: var(--color--mint--300);
  --dremio--avatar--color: #202124;
}
.dremio-light .dremio-avatar--color-0 {
  --dremio--avatar--background: var(--color--green--200);
  --dremio--avatar--color: #202124;
}

.dremio-dark .dremio-avatar--color-1 {
  --dremio--avatar--background: #79292c;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-2, .dremio-dark .dremio-avatar--color-3 {
  --dremio--avatar--background: #8e5804;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-4, .dremio-dark .dremio-avatar--color-5 {
  --dremio--avatar--background: #275888;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-6 {
  --dremio--avatar--background: #54286d;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-7 {
  --dremio--avatar--background: #8d2f66;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-8 {
  --dremio--avatar--background: #432f71;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-9 {
  --dremio--avatar--background: #005354;
  --dremio--avatar--color: white;
}
.dremio-dark .dremio-avatar--color-0 {
  --dremio--avatar--background: #105434;
  --dremio--avatar--color: white;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.bar-group {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.bar {
  align-items: center;
  block-size: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
}
.bar__container {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 1px;
  border-radius: 2px;
  padding: 6px;
  padding-bottom: 0;
  transition: background-color 125ms ease;
  width: 100%;
}
.bar__container--stacked {
  flex-direction: column-reverse;
  justify-content: end;
}
.bar__container--grouped {
  align-items: end;
  flex-direction: row;
}
.bar__segment {
  border-radius: 2px;
  max-width: 12px;
  width: 100%;
  min-width: 6px;
  display: flex;
  transition: height 400ms cubic-bezier(0.22, 1, 0.36, 1);
}
.bar__segment--bg-default {
  background-color: var(--color--brand--200);
}
.bar__label {
  font-weight: 500;
  font-size: 0.875em;
  font-variant-numeric: lining-nums tabular-nums;
  letter-spacing: -0.025em;
  padding: 4px;
  text-wrap: nowrap;
}

.viz-segment-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px 16px;
  font-size: 13px;
}
.viz-segment-list dt {
  align-items: center;
  display: inline-flex;
  padding-inline-end: 4px;
}
.viz-segment-list dd {
  align-items: center;
  display: inline-flex;
  justify-content: end;
  text-align: start;
  word-break: break-all;
  font-weight: 600;
  font-variant-numeric: lining-nums tabular-nums;
  letter-spacing: -0.025em;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-button-group {
  display: inline-flex;
  flex-direction: row;
  gap: var(--dremio--button-group--gap);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-button-group {
  --dremio--button-group--gap: var(--dremio--spacing--1);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-button {
  box-sizing: border-box;
  display: inline-flex;
  max-inline-size: 100%;
  vertical-align: middle;
}
.dremio-button:disabled, .dremio-button[aria-disabled=true] {
  cursor: not-allowed;
}
.dremio-button__content {
  display: inline-flex;
}
.dremio-button__content > * {
  max-block-size: 100%;
}

a.dremio-button {
  text-decoration: none !important;
}

a.dremio-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}

button.dremio-button {
  padding: 0;
}

.dremio-button .dremio-tooltip__child-wrap {
  flex: 1;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-button {
  border-color: transparent;
  min-width: 100px;
  transition: background 75ms ease-in-out, color 75ms ease-in-out;
}
.dremio-button__content {
  padding-inline: 10px;
}
.dremio-button:disabled:not([aria-busy=true]), .dremio-button[aria-disabled=true]:not([aria-busy=true]) {
  border-color: transparent !important;
  color: var(--text--disabled) !important;
}
.dremio-button--secondary {
  background: var(--fill--primary);
  border-color: var(--border--neutral--solid);
  color: var(--text--faded);
}
.dremio-button--secondary:hover:not([aria-busy=true]), .dremio-button--secondary:focus-visible:not([aria-busy=true]) {
  background: var(--fill--secondary);
}
.dremio-button--tertiary {
  background: transparent;
  border-color: transparent;
  color: var(--text--brand);
}
.dremio-button--tertiary:hover:not([aria-busy=true]), .dremio-button--tertiary:focus-visible:not([aria-busy=true]) {
  background: var(--fill--primary--hover);
}
.dremio-button--tertiary:disabled:not([aria-busy=true]), .dremio-button--tertiary[aria-disabled=true]:not([aria-busy=true]) {
  background: transparent !important;
}
.dremio-button--primary-danger {
  background: var(--fill--danger--solid);
  border-color: transparent;
  color: white;
}
.dremio-button--primary-danger:hover {
  background: var(--fill--danger--bold);
}
.dremio-button--secondary-danger {
  background: transparent;
  border-color: var(--border--neutral--solid);
  color: var(--fill--danger--solid);
}
.dremio-button--secondary-danger:hover {
  background: var(--fill--danger);
  border-color: var(--fill--danger);
}
.dremio-button--tertiary-danger {
  background: transparent;
  border-color: transparent;
  color: var(--fill--danger--solid);
}
.dremio-button--tertiary-danger:hover:not([aria-busy=true]), .dremio-button--tertiary-danger:focus-visible:not([aria-busy=true]) {
  background: var(--fill--danger);
}
.dremio-button--tertiary-danger:disabled:not([aria-busy=true]), .dremio-button--tertiary-danger[aria-disabled=true]:not([aria-busy=true]) {
  background: transparent !important;
}

@media (prefers-contrast: more) {
  .dremio-light .dremio-button--primary {
    background-color: var(--color--brand--500);
  }
  .dremio-light .dremio-button--primary:hover:not([aria-busy=true]), .dremio-light .dremio-button--primary:focus-visible:not([aria-busy=true]) {
    background-color: var(--color--brand--600);
  }
  .dremio-light .dremio-button:disabled, .dremio-light .dremio-button[aria-disabled=true] {
    color: var(--color--neutral--500);
  }
}

.dremio-light .bg-brand-500 .dremio-button--tertiary,
.dremio-dark .bg-brand-500 .dremio-button--tertiary {
  color: inherit;
}
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
a:hover .dremio-card, a:focus-visible .dremio-card,
button:hover .dremio-card,
button:focus-visible .dremio-card, .dremio-card.hovered:not(.disabled) {
  --dremio--card--background: var(--fill--primary--hover);
}

.dremio-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dremio-card__header {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.dremio-card__header:empty {
  display: none;
}
.dremio-card__title {
  align-items: center;
  display: inline-flex;
}
.dremio-card__title, .dremio-card__title > * {
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
}
.dremio-card__toolbar {
  margin-inline-start: auto;
  color: var(--icon--primary);
}
.dremio-card__body {
  flex: 1;
  overflow-y: auto;
}

.dremio-card.disabled .dremio-card__title *, .dremio-card.disabled .dremio-card__body * {
  opacity: 0.5;
}

a:has(.dremio-card),
button:has(.dremio-card) {
  text-decoration: none !important;
}
a:hover, a:focus-visible,
button:hover,
button:focus-visible {
  cursor: pointer;
}
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-card__title, .dremio-card__title > * {
  font-size: 14px;
}

.dremio-card__header {
  border-block-end: 1px solid var(--border--color);
}

.dremio-card--no-body-padding .dremio-card__body {
  padding: 0;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.code-view {
  border: 1px solid var(--border--neutral);
}
.code-view__header {
  background-color: var(--fill--tertiary);
}
.code-view__content {
  background-color: var(--fill--secondary);
}

.hljs-keyword {
  color: #124db4;
}
.hljs-string {
  color: #004e52;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-description-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  line-height: var(--dremio--description-list--line-height);
  inline-size: 100%;
  margin-block: calc(var(--dremio--description-list--padding-block) * -1);
}
.dremio-description-list__term,
.dremio-description-list dt, .dremio-description-list__description,
.dremio-description-list dd {
  padding-block: var(--dremio--description-list--padding-block);
}
.dremio-description-list__term,
.dremio-description-list dt {
  align-items: center;
  color: var(--dremio--description-list--term--color);
  display: inline-flex;
  font-size: var(--dremio--description-list--term--font-size);
  padding-inline-end: var(--dremio--description-list--term--gap);
}
.dremio-description-list__description,
.dremio-description-list dd {
  align-items: center;
  color: var(--dremio--description-list--description--color);
  display: inline-flex;
  font-size: var(--dremio--description-list--description--font-size);
  justify-content: end;
  text-align: start;
  word-break: break-all;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-description-list {
  --dremio--description-list--line-height: 20px;
  --dremio--description-list--padding-block: var(--dremio--spacing--1);
  --dremio--description-list--description--color: black;
  --dremio--description-list--description--font-size: 14px;
  --dremio--description-list--term--color: var(--text--faded);
  --dremio--description-list--term--font-size: 12px;
  --dremio--description-list--term--gap: var(--dremio--spacing--1);
}

.dremio-dark .dremio-description-list dd {
  color: white;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* stylelint-disable selector-class-pattern */
.dremio-dialog-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--dremio--dialog-content--color);
  font-weight: var(--dremio--dialog-content--font-weight);
  border-radius: var(--dremio--dialog-content--border-radius);
}
.dremio-dialog-content__header, .dremio-dialog-content__footer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.dremio-dialog-content__header:empty, .dremio-dialog-content__footer:empty {
  display: none;
}
.dremio-dialog-content__header {
  border-block-end: var(--dremio--dialog-content--border);
  block-size: var(--dremio--dialog-content--header--height);
}
.dremio-dialog-content__header-icon {
  margin-inline-end: var(--dremio--spacing--1);
  block-size: var(--dremio--dialog-content--icon--size);
  inline-size: var(--dremio--dialog-content--icon--size);
  margin-block-start: 0.225em;
  color: var(--dremio--dialog-content--icon--color);
}
.dremio-dialog-content__header-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var(--dremio--dialog-content--title--font-size);
  font-weight: var(--dremio--dialog-content--title--font-weight);
  gap: 6px;
  inline-size: 100%;
}
.dremio-dialog-content__header-toolbar {
  display: flex;
}
.dremio-dialog-content__error {
  margin-block-start: var(--dremio--dialog-content--padding);
}
.dremio-dialog-content__main {
  padding-block: var(--dremio--dialog-content--padding);
  overflow-y: auto;
}
.dremio-dialog-content__footer {
  border-block-start: var(--dremio--dialog-content--border);
  display: flex;
  flex-direction: row;
  block-size: var(--dremio--dialog-content--footer--height);
}
.dremio-dialog-content__footer:empty {
  display: none;
}
.dremio-dialog-content__footer-actions {
  margin-inline-start: auto;
}
.dremio-dialog-content--no-overflow .dremio-dialog-content__main {
  display: flex;
  overflow: hidden;
}
.dremio-dialog-content--expanded {
  block-size: calc(100vh - var(--dremio--modal-container--window-margin));
}
.dremio-dialog-content > * {
  padding-inline: var(--dremio--dialog-content--padding);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-dialog-content {
  --dremio--dialog-content--border: 1px solid var(--border--neutral);
  --dremio--dialog-content--color: var(--text--primary);
  --dremio--dialog-content--font-weight: 400;
  --dremio--dialog-content--header--height: 55px;
  --dremio--dialog-content--title--font-size: 16px;
  --dremio--dialog-content--title--font-weight: 600;
  --dremio--dialog-content--footer--height: var(
    --dremio--dialog-content--header--height
  );
  --dremio--dialog-content--border-radius: var(--dremio--radius--1);
  --dremio--dialog-content--padding: var(--dremio--spacing--2);
  --dremio--dialog-content--icon--size: 22px;
  --dremio--dialog-content--icon--color: var(--icon--primary);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-drawer {
  box-sizing: border-box;
  inset: 0;
  block-size: 100vh;
  margin: 0 0 0 auto;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
}
.dremio-drawer[open], .dremio-drawer--open {
  display: flex;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-drawer {
  background: var(--fill--primary);
  border: none;
  border-start-start-radius: var(--dremio--radius--1);
  border-start-end-radius: var(--dremio--radius--1);
  box-shadow: var(--dremio--shadow--left);
}
.dremio-drawer .dremio-dialog-content {
  --dremio--dialog-content--padding: var(--dremio--spacing--1);
}

.elevation-sunken {
  background-color: var(--bg--sunken);
  color: var(--color--sunken);
}
.elevation-default {
  background-color: var(--fill--primary);
  color: var(--text--primary);
}
.elevation-raised {
  background-color: var(--bg--raised);
  color: var(--color--raised);
}
.elevation-overlay {
  background-color: var(--bg--overlay);
  color: var(--color--overlay);
}

.dremio-empty-overlay {
  align-items: center;
  block-size: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  background: var(--dremio-empty-overlay--background);
  color: var(--dremio-empty-overlay--color);
  font-size: var(--dremio-empty-overlay--font-size);
  padding: var(--dremio-empty-overlay--padding);
}

.dremio-empty-overlay {
  --dremio-empty-overlay--background: var(--color--neutral--75);
  --dremio-empty-overlay--color: var(--text--faded);
  --dremio-empty-overlay--font-size: 18px;
  --dremio-empty-overlay--padding: var(--dremio--spacing--2);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-error-display {
  --dremio--error-display--padding: var(--dremio--spacing--2);
  --dremio--error-display--title--font-size: 16px;
  --dremio--error-display--title--font-weight: 600;
  --dremio--error-display--title--line-height: 24px;
  --dremio--error-display--title--icon--size: 120px;
  block-size: 100%;
  box-sizing: border-box;
  font-size: 14px;
  inline-size: 100%;
  line-height: 20px;
  min-inline-size: 30ch;
  padding: var(--dremio--error-display--padding);
  text-align: center;
}
.dremio-error-display__wrapper {
  margin-inline: auto;
  max-inline-size: 75ch;
  inline-size: 100%;
}
.dremio-error-display__wrapper > * + * {
  margin-block-start: var(--dremio--spacing--1);
}
.dremio-error-display__title-text {
  font-size: var(--dremio--error-display--title--font-size);
  font-weight: var(--dremio--error-display--title--font-weight);
  line-height: var(--dremio--error-display--title--line-height);
}
.dremio-error-display__title-icon {
  block-size: 100%;
  inline-size: 100%;
  max-block-size: var(--dremio--error-display--title--icon--size);
  max-inline-size: var(--dremio--error-display--title--icon--size);
  margin-block-end: var(--dremio--spacing--2);
}
.dremio-error-display__codeblock {
  user-select: all;
  white-space: pre-wrap;
  inline-size: 100%;
  background: var(--fill--primary);
  color: var(--text--primary);
  border: 1px solid black;
  border-radius: var(--dremio--radius--1);
  padding: var(--dremio--spacing--2);
  max-block-size: 200px;
  overflow: auto;
  text-align: start;
}
.dremio-error-display__collapsible-stacktrace {
  text-align: start;
}
.dremio-error-display__collapsible-stacktrace-icon {
  margin-inline-start: -13px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-external-link--inline {
  /* stylelint-disable-next-line selector-class-pattern */
}
.dremio-external-link--inline .dremio-external-link__icon {
  block-size: 12px;
  inline-size: 12px;
  margin-block-start: -5px;
  margin-inline-start: -1px;
}
.dremio-external-link--list {
  /* stylelint-disable-next-line selector-class-pattern */
}
.dremio-external-link--list .dremio-external-link__icon {
  block-size: 20px;
  inline-size: 20px;
}

.dremio-light .bg-brand-500 .dremio-external-link {
  color: #16e3ee;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.drop-shadow-lg, .float-container {
  box-shadow: var(--dremio--shadow--layer-1);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.float-container {
  background: var(--fill--popover);
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 5000;
}

.float-container {
  transition-property: opacity;
  will-change: opacity;
  transition-duration: 100ms;
  opacity: 0;
}
.float-container-enter, .float-container-appear {
  opacity: 0;
}
.float-container-enter-active, .float-container-appear-active, .float-container-appear-done, .float-container-enter-done {
  opacity: 1;
}
.float-container-exit {
  opacity: 1;
}
.float-container-exit-active {
  opacity: 0;
}
.float-container-exit-done {
  display: none;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-icon-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  user-select: none;
  color: var(--icon--primary);
  padding: var(--dremio--spacing--05);
  transition: color 125ms ease-in-out;
  vertical-align: middle;
}
.dremio-icon-button:hover, .dremio-icon-button:focus-visible, .dremio-icon-button[aria-expanded=true] {
  color: var(--icon--hover);
}
.dremio-icon-button:disabled, .dremio-icon-button[aria-disabled=true] {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--icon--disabled);
}
.dremio-icon-button > * {
  pointer-events: none;
}
.dremio-icon-button dremio-icon {
  block-size: 24px;
  inline-size: 24px;
}

button.dremio-icon-button {
  background: transparent;
  border: none;
}

a.dremio-icon-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-icon-label {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: var(--dremio--icon-label--gap);
}
.dremio-icon-label dremio-icon,
.dremio-icon-label img {
  display: inline-flex;
  block-size: var(--dremio--icon-label--icon-size);
  inline-size: var(--dremio--icon-label--icon-size);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-icon-label {
  --dremio--icon-label--gap: var(--dremio--spacing--05);
  --dremio--icon-label--icon-size: 24px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
dremio-icon {
  display: inline-flex;
  block-size: 20px;
  inline-size: 20px;
  flex-shrink: 0;
  vertical-align: middle;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-layout-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  block-size: 100%;
  overflow: hidden;
  /* stylelint-disable-next-line selector-class-pattern */
  /* stylelint-disable-next-line selector-class-pattern */
}
.dremio-layout-container > * {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: auto;
}
.dremio-layout-container > .--fixed {
  flex-grow: 0;
  flex-shrink: 0;
}
.dremio-layout-container.--vertical {
  flex-direction: column;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-layout-grid {
  display: grid;
  grid-gap: var(--gap-size);
  grid-template-columns: repeat(var(--fill-mode, auto-fit), minmax(min(var(--min-size, min-content), 100%), 1fr));
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-layout-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dremio-layout-stack > * {
  margin-block: 0;
}
.dremio-layout-stack > * + * {
  margin-block-start: var(--space);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-light .dremio-tooltip:not(.dremio-tooltip-rich) a,
.dremio-light .dremio-tooltip:not(.dremio-tooltip-rich) .link {
  color: var(--text--link);
}

button.link {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--text--link);
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}
button.link:hover, button.link:focus {
  color: var(--text--link);
  text-decoration: underline;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.listbox-group + .listbox-group:before,
.listbox-group + .listbox-group-label:before {
  display: block;
  border-top: 1px solid var(--border--neutral);
  content: "";
}

.listbox-group + .listbox-group-label:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.listbox-group-label {
  color: var(--text--primary);
  position: relative;
}

.listbox-item:hover {
  background: var(--fill--primary--hover);
}
.listbox-item--active, .listbox-item[aria-selected=true] {
  background: var(--fill--primary--selected);
}
.listbox-item--active:hover, .listbox-item[aria-selected=true]:hover {
  background: var(--fill--primary--selected--hover);
}
.listbox-item[aria-disabled=true], .listbox-item:disabled, .listbox-item[disabled] {
  background: transparent;
  color: var(--text--disabled);
  cursor: default;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.loader-bars {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#eeeff1 0 0);
  background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
  animation: b2 1s infinite linear;
}

@keyframes b2 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  20% {
    background-size: 20% 60%, 20% 100%, 20% 100%;
  }
  40% {
    background-size: 20% 80%, 20% 60%, 20% 100%;
  }
  60% {
    background-size: 20% 100%, 20% 80%, 20% 60%;
  }
  80% {
    background-size: 20% 100%, 20% 100%, 20% 80%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.form-control {
  background: var(--fill--primary);
  color: var(--text--primary);
  border: 1px solid var(--border--neutral--solid);
  box-sizing: border-box;
  font-size: inherit;
  outline: none;
}
.form-control:hover {
  border: 1px solid var(--border--hover);
}
.form-control:focus, .form-control:focus-within {
  border: 1px solid var(--border--focused);
}
.form-control input {
  appearance: none;
  background: transparent;
  outline: none;
}
.form-control input:disabled, .form-control input[aria-disabled=true] {
  cursor: not-allowed !important;
  border-color: var(--border--neutral--solid) !important;
}
.form-control__prefix {
  background-color: var(--fill--secondary);
  padding-block: 5px;
  margin-inline-start: -4px;
  margin-inline-end: 4px;
  padding-inline: 4px;
}
input.form-control:not([type=radio]):not([type=checkbox]) {
  padding-inline: 0.5em;
}

.form-control:disabled, .form-control[aria-disabled=true] {
  cursor: not-allowed !important;
  border-color: var(--border--neutral--solid) !important;
}
.form-control:invalid, .form-control[aria-invalid=true] {
  border-color: var(--color--danger--400);
}
.form-control[type=checkbox] {
  border-radius: 0.2em;
  width: 16px;
  min-width: var(--scale-2);
  height: 16px;
}
.form-control[type=checkbox]:disabled {
  border-color: var(--bg) !important;
}
.form-control[type=checkbox]:checked {
  background: var(--fill--toggle--checked);
  border: 1px solid var(--fill--toggle--checked);
}
.form-control[type=checkbox]:checked::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaW50ZXJmYWNlL2NoZWNrYm94IiB2aWV3Qm94PSIwIDAgMTIgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC40Njk3IDAuOTY4OTQ2TDMuOTk5NjggNy40Mzg5NUwxLjUyOTY4IDQuOTY4OTVMMS40NDU2OCA0Ljg5NTk1QzEuMjkzNDcgNC43ODQzIDEuMTA0NzYgNC43MzQxNSAwLjkxNzIgNC43NTU1MUMwLjcyOTY0NCA0Ljc3Njg3IDAuNTU3MDQxIDQuODY4MTcgMC40MzM4MzggNS4wMTExOUMwLjMxMDYzNiA1LjE1NDIxIDAuMjQ1ODk3IDUuMzM4NDMgMC4yNTI1NCA1LjUyNzA5QzAuMjU5MTg0IDUuNzE1NzQgMC4zMzY3MjEgNS44OTQ5NSAwLjQ2OTY3OSA2LjAyODk1TDMuNDY5NjggOS4wMjg5NUwzLjU1MzY4IDkuMTAxOTVDMy42OTgxNSA5LjIwODY2IDMuODc2MTIgOS4yNTk5OCA0LjA1NTIzIDkuMjQ2NTlDNC4yMzQzNCA5LjIzMzE5IDQuNDAyNjkgOS4xNTU5NiA0LjUyOTY4IDkuMDI4OTVMMTEuNTI5NyAyLjAyODk1TDExLjYwMjcgMS45NDQ5NUMxMS43MTQzIDEuNzkyNzQgMTEuNzY0NSAxLjYwNDAyIDExLjc0MzEgMS40MTY0N0MxMS43MjE4IDEuMjI4OTEgMTEuNjMwNSAxLjA1NjMxIDExLjQ4NzQgMC45MzMxMDZDMTEuMzQ0NCAwLjgwOTkwNCAxMS4xNjAyIDAuNzQ1MTY0IDEwLjk3MTUgMC43NTE4MDhDMTAuNzgyOSAwLjc1ODQ1MSAxMC42MDM3IDAuODM1OTg5IDEwLjQ2OTcgMC45Njg5NDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  position: absolute;
  inset: 10%;
}
.form-control[type=checkbox]:checked:disabled::after {
  filter: invert(0.25);
}
.form-control[type=checkbox]:checked:hover {
  background: var(--fill--toggle--checked--hover);
  border: 1px solid var(--fill--toggle--checked--hover);
}
.form-control[type=checkbox]:focus-visible {
  outline-offset: 4px;
  outline: 1.5px solid var(--border--focused);
}
.form-control[type=checkbox]:indeterminate {
  border: 1.5px solid var(--fill--toggle--checked);
}
.form-control[type=checkbox]:indeterminate::after {
  content: "";
  display: block;
  color: white;
  width: 8px;
  height: 8px;
  background-color: var(--fill--toggle--checked);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-control[type=checkbox]:indeterminate:hover {
  border: 1.5px solid var(--fill--toggle--checked--hover);
}
.form-control[type=checkbox]:indeterminate:hover::after {
  background-color: var(--fill--toggle--checked--hover);
}
.form-control[type=checkbox]:checked, .form-control[type=checkbox]:indeterminate {
  padding: 0;
}
.form-control[type=checkbox] {
  appearance: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 !important;
  position: relative;
}
.form-control[type=radio] {
  appearance: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
}
.form-control[type=radio] {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.form-control[type=radio]:checked {
  border: 1px solid var(--fill--toggle--checked);
  background: var(--fill--toggle--checked);
}
.form-control[type=radio]:checked:hover {
  background-color: var(--fill--toggle--checked--hover);
  border: 1px solid var(--fill--toggle--checked--hover);
}
.form-control[type=radio]::after {
  background: var(--fill--primary);
  content: "";
  position: absolute;
  inset: 100%;
  border-radius: 50%;
}
.form-control[type=radio]:checked::after {
  inset: 20%;
}
.form-control[type=radio]:hover {
  border: 1px solid var(--border--hover);
}
.form-control[type=radio]:focus-visible {
  outline-offset: 4px;
  outline: 1.5px solid var(--border--focused);
}

.dremio-dark .form-control[type=checkbox]:checked::after {
  filter: invert(1);
}
.dremio-dark .form-control[type=checkbox]:checked:disabled::after {
  filter: invert(0.6);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-modal-container {
  block-size: max-content;
  max-block-size: calc(100% - var(--dremio--modal-container--window-margin));
  inline-size: calc(100% - var(--dremio--modal-container--window-margin));
  max-inline-size: max-content;
  overflow: hidden;
  margin: auto !important;
  background: var(--dremio--modal-container--background);
  border: var(--dremio-modal-container--border);
  border-radius: var(--dremio--modal-container--border-radius);
  box-shadow: var(--dremio--modal-container--shadow);
}
.dremio-modal-container[open], .dremio-modal-container--open {
  display: flex;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@keyframes dremio-modal-container-fadein {
  from {
    background: rgba(0, 0, 0, 0);
    /* stylelint-disable-next-line property-no-unknown */
    backdrop-filter: blur(0);
  }
  to {
    background: rgba(41, 56, 73, 0.5);
    /* stylelint-disable-next-line property-no-unknown */
    background-filter: blur(2px);
  }
}
dialog.dremio-modal-container {
  padding: 0;
}

.dremio-modal-container {
  --dremio--modal-container--background: var(--fill--primary);
  --dremio--modal-container--backdrop--background: rgba(41 56 73 / 50%);
  --dremio--modal-container--backdrop--blur: 2px;
  --dremio--modal-container--border: 1px solid var(--border--neutral);
  --dremio--modal-container--border-radius: var(--dremio--radius--1);
  --dremio--modal-container--shadow: var(--dremio--shadow--layer-1);
  --dremio--modal-container--window-margin: 30px;
}
.dremio-modal-container::backdrop {
  animation: dremio-modal-container-fadein 100ms forwards;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.multi-checkbox-popover .listbox {
  width: 240px;
  max-height: 400px;
  box-shadow: var(--dremio--shadow--layer-1);
  padding: 4px;
}
.multi-checkbox-popover .listbox-item dremio-icon {
  width: 24px;
  height: 24px;
}
.multi-checkbox-popover .listbox-group {
  overflow: auto;
}
.multi-checkbox-popover__button {
  width: 160px;
}
.multi-checkbox-popover__button__hasValues {
  border-color: var(--border--focused);
  background-color: var(--fill--primary--selected);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-page {
  block-size: 100vh;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}
.dremio-page__header {
  z-index: 10;
}
.dremio-page__header > * {
  padding-inline: var(--dremio--page--padding);
}
.dremio-page__content {
  flex: 1;
  /**
   * All content should fit within the available horizontal space -- if it doesn't,
   * horizontally scrolling the entire content area isn't a good solution.
   */
  overflow-x: hidden;
  overflow-y: auto;
}
.dremio-page__content > *:last-child {
  padding-block-end: var(--dremio--page--padding);
}
.dremio-page__content > * {
  /**
   * Every child of the page should inherit a default amount of horizontal margin.
   * For any elements that want to bypass the margin, they can simply set `margin:0`
   */
  margin-inline: var(--dremio--page--padding);
}

.dremio-layout-container .dremio-page {
  block-size: auto;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-page {
  --dremio--page--padding: var(--dremio--spacing--2);
}

/* stylelint-disable-next-line selector-class-pattern */
.dremio-page__content > .centered-container {
  max-block-size: 600px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.popover {
  z-index: 1000;
  background-color: var(--popover--background);
  border-radius: var(--popover--radius);
  transition-property: opacity, transform;
  will-change: transform;
}
.popover--bottom {
  transform-origin: bottom;
}
.popover--top {
  transform-origin: top;
}
.popover--left {
  transform-origin: left;
}
.popover--right {
  transform-origin: right;
}
.popover__arrow {
  position: absolute;
  transform: rotate(45deg);
  left: var(--popover--arrow--left);
  background: var(--popover--arrow--background);
  block-size: var(--popover--arrow--size);
  inline-size: var(--popover--arrow--size);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.popover {
  --popover--background: var(--fill--popover);
  --popover--arrow--left: -4px;
  --popover--arrow--background: var(--fill--popover);
  --popover--arrow--size: 8px;
  --popover--radius: 4px;
  transition-duration: 100ms;
  opacity: 0;
  transform: scale(0.8);
}
.popover-enter, .popover-appear {
  opacity: 0;
  transform: scale(0.8);
}
.popover-enter-active, .popover-appear-active, .popover-appear-done, .popover-enter-done {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.popover-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.popover-exit-active {
  opacity: 0;
  transform: scale(0.8);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-prose {
  font-size: 14px;
  line-height: 20px;
  max-inline-size: 75ch;
  text-align: start;
  white-space: normal;
}
.dremio-prose > * + * {
  margin-block-start: 1em;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-scroll-shadow {
  position: relative;
  overflow: visible;
  /* stylelint-disable-next-line selector-class-pattern */
}
.dremio-scroll-shadow::after {
  content: "";
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 72ms ease-in-out;
  z-index: 500;
}
.dremio-scroll-shadow--top::after {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1) inset;
  inset-block-end: 0;
  inset-inline: 0;
  block-size: 30px;
  transform: translate(0, 100%);
}
.dremio-scroll-shadow--bottom::after {
  box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.1) inset;
  inset-block-start: 0;
  inset-inline: 0;
  block-size: 30px;
  transform: translate(0, -100%);
}
.dremio-scroll-shadow.--scrolled::after {
  opacity: 1;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-section {
  border: var(--dremio--section--border);
  border-radius: var(--dremio--section--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dremio-section__header {
  align-items: center;
  border-block-end: var(--dremio--section--header--border);
  display: flex;
  flex-direction: row;
  line-height: var(--dremio--section--header--line-height);
}
.dremio-section__title {
  align-items: center;
  display: inline-flex;
}
.dremio-section__title, .dremio-section__title > * {
  font-size: var(--dremio--section--title--font-size);
  font-weight: var(--dremio--section--title--font-weight);
  margin: 0;
  padding: 0;
}
.dremio-section__toolbar {
  margin-inline-start: auto;
}
.dremio-section__body {
  flex: 1;
  overflow-y: auto;
  padding: var(--dremio--section--body--padding);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-section {
  --dremio--section--padding: var(--dremio--spacing--2);
  --dremio--section--header--line-height: 24px;
  --dremio--section--title--font-size: 16px;
  --dremio--section--title--font-weight: 600;
  --dremio--section--body--padding: var(--dremio--spacing--1) 0;
}
.dremio-section + hr {
  background-color: var(--color--neutral--75);
  border: none;
  block-size: 1px;
  margin-block: var(--dremio--spacing--2);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-section-message {
  display: flex;
  flex-direction: row;
  background: var(--dremio-section-message--background);
  color: var(--dremio-section-message--color);
  font-size: var(--dremio-section-mesage--header--font-size);
  padding: var(--dremio-section-message--padding);
}
.dremio-section-message__icon {
  flex-shrink: 0;
  color: var(--icon--primary);
}
.dremio-section-message__content {
  flex: 1;
}
.dremio-section-message__header {
  font-weight: var(--dremio-section-mesage--header--font-weight);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-section-message {
  --dremio-section-message--color: var(--text--primary);
  --dremio-section-mesage--header--font-size: 12px;
  --dremio-section-message--padding: var(--dremio--spacing--1);
  --dremio-section-mesage--header--font-weight: 600;
  gap: var(--dremio--spacing--1);
}
.dremio-section-message__content {
  line-height: 20px;
}
.dremio-section-message__content > * + * {
  margin-block-start: var(--dremio--spacing--05);
}
.dremio-section-message--danger {
  --dremio-section-message--background: var(--fill--danger);
}
.dremio-section-message--warning {
  --dremio-section-message--background: var(--fill--warning);
}
.dremio-section-message--warning dremio-icon {
  color: var(--fill--warning--solid);
}
.dremio-section-message--information {
  --dremio-section-message--background: var(--fill--info);
}
.dremio-section-message--information dremio-icon {
  color: var(--fill--info--solid);
}
.dremio-section-message--success {
  --dremio-section-message--background: var(--fill--success);
}
.dremio-section-message--success dremio-icon {
  color: var(--fill--success--solid);
}
.dremio-section-message--discovery {
  --dremio-section-message--background: lch(93 7.31 286.07);
  --dremio-section-message--color: lch(5 36.31 292.07);
}
.dremio-section-message--discovery dremio-icon {
  color: lch(26 61.31 292.07);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.section-tabs {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.section-tab {
  border-bottom: 2px solid transparent;
  color: inherit;
  font-weight: 400;
  padding-block-end: 8px;
  padding-inline: 8px;
  text-decoration: none !important;
}
.section-tab:hover {
  color: var(--text--brand);
}
.section-tab:focus-visible {
  color: var(--text--brand);
  outline: 1px auto -webkit-focus-ring-color !important;
}
.section-tab[aria-current=true], .section-tab[aria-current=page], .section-tab[aria-selected=true] {
  border-bottom-color: var(--border--brand--solid);
  color: var(--text--brand);
  font-weight: 600;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-segmented-control {
  display: flex;
  flex-direction: row;
  gap: var(--dremio--segmented-control-option--gap);
}
.dremio-segmented-control > *:first-child {
  border-start-start-radius: var(--dremio--segmented-control--border-radius);
  border-end-start-radius: var(--dremio--segmented-control--border-radius);
  padding-inline-start: calc(var(--dremio--segmented-control-option--padding) + 1px);
}
.dremio-segmented-control > *:last-child {
  border-start-end-radius: var(--dremio--segmented-control--border-radius);
  border-end-end-radius: var(--dremio--segmented-control--border-radius);
  padding-inline-end: calc(var(--dremio--segmented-control-option--padding) + 1px);
}
.dremio-segmented-control-option {
  background: var(--dremio--segmented-control-option--background);
  border: var(--dremio--segmented-control-option--border);
  color: var(--dremio--segmented-control-option--color);
  font-weight: var(--dremio--segmented-control-option--font-weight);
  padding: var(--dremio--segmented-control-option--padding);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-segmented-control {
  --dremio--segmented-control--border-radius: var(--dremio--radius--1);
  --dremio--segmented-control-option--gap: 0;
}
.dremio-segmented-control-option {
  transition: background 75ms ease-in-out, color 75ms ease-in-out;
  --dremio--segmented-control-option--background: var(--fill--secondary);
  --dremio--segmented-control-option--border: none;
  --dremio--segmented-control-option--color: var(--icon--primary);
  --dremio--segmented-control-option--font-weight: 600;
  --dremio--segmented-control-option--padding: var(--dremio--spacing--05);
}
.dremio-segmented-control-option:hover {
  --dremio--segmented-control-option--background: var(
    --fill--primary--hover
  );
}
.dremio-segmented-control-option[aria-pressed=true] {
  --dremio--segmented-control-option--background: var(
    --fill--primary--selected
  );
  --dremio--segmented-control-option--color: var(--icon--brand);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-skeleton {
  display: inline-block;
  vertical-align: middle;
  background: var(--dremio--skeleton--background);
  border-radius: var(--dremio--skeleton--border-radius);
}
.dremio-skeleton--text {
  block-size: 1em;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
.dremio-skeleton {
  --dremio--skeleton--border-radius: var(--dremio--radius--1);
  background-image: linear-gradient(90deg, var(--border--neutral) 0px, #e5e5e5 40px, var(--border--neutral) 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

.dremio-dark .dremio-skeleton {
  background-image: linear-gradient(90deg, lch(40% 1 242) 0px, lch(43% 1 242) 40px, lch(40% 1 242) 80px);
}

.dremio-spinner-overlay {
  z-index: 100;
  transition: color 500ms 150ms, opacity 200ms;
  color: transparent;
  opacity: 0;
}
.dremio-spinner-overlay-enter, .dremio-spinner-overlay-appear {
  color: transparent;
  opacity: 0;
}
.dremio-spinner-overlay-enter-active, .dremio-spinner-overlay-appear-active, .dremio-spinner-overlay-appear-done, .dremio-spinner-overlay-enter-done {
  color: var(--text--faded);
  opacity: 1;
}
.dremio-spinner-overlay-exit {
  color: var(--text--faded);
  opacity: 1;
}
.dremio-spinner-overlay-exit-active {
  color: transparent;
  opacity: 0;
}

.dremio-spinner-overlay {
  background: var(--fill--primary);
}
.dremio-spinner-overlay .dremio-spinner {
  --dremio--spinner--size: 30px;
}

.dremio-spinner-overlay {
  background: var(--fill--primary);
}
.dremio-spinner-overlay .dremio-spinner {
  --dremio--spinner--size: 30px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@keyframes dremio-spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dremio-spinner {
  color: var(--dremio--spinner--color, inherit);
  display: inline;
}
.dremio-spinner > * {
  block-size: var(--dremio--spinner--size);
  inline-size: var(--dremio--spinner--size);
}

.dremio-spinner > * {
  animation-name: dremio-spinner-rotate;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

:root {
  --dremio--spinner--size: 20px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.language-sql {
  color: var(--text--faded);
}
.language-sql .hljs-keyword {
  color: var(--sql--syntax--keywords);
}
.language-sql .hljs-string {
  color: var(--sql--syntax--string);
}
.language-sql .hljs-number {
  color: var(--sql--syntax--number);
}
.language-sql .hljs-built_in {
  color: var(--sql--syntax--function);
}
.language-sql .hljs-type {
  color: var(--sql--syntax--datatype);
}
.language-sql .hljs-comment {
  color: var(--sql--syntax--comment);
}

.syntax-highlighter--nowrap code {
  white-space: nowrap !important;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.scrollbar-hidden {
  scrollbar-width: none;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.tab-list {
  align-items: center;
  background: var(--fill--primary);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.tab-list-tabs {
  display: inline-flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none;
}
.tab-list-tabs::-webkit-scrollbar {
  display: none;
}

.tab-list-tab {
  align-items: center;
  background: inherit;
  block-size: var(--scale-5);
  border: none;
  border-block: 2px solid transparent;
  color: var(--text--faded);
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  font-variant-numeric: lining-nums tabular-nums;
  letter-spacing: -0.025em;
  gap: var(--scale-1);
  min-inline-size: 110px;
  max-inline-size: 260px;
  padding: var(--scale-1);
  position: relative;
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
}
.tab-list-tab__label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.tab-list-tab__close-icon {
  margin-inline-start: auto;
  padding: 0;
}
.tab-list-tab:hover .tab-list-tab__label {
  color: var(--text--brand);
}
.tab-list-tab[aria-selected=true] {
  border-block-end-color: var(--border--brand--solid);
  font-weight: 600;
  letter-spacing: -0.0375em;
}
.tab-list-tab::after {
  border-inline-end: 1px solid var(--border--neutral--solid);
  content: "";
  position: absolute;
  height: var(--scale-3);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tab-list-tab .tab-list__tab-menu button {
  opacity: 0;
}
.tab-list-tab .tab-list__tab-menu button[aria-expanded=true], .tab-list-tab .tab-list__tab-menu button:focus {
  opacity: 1;
}
.tab-list-tab:hover .tab-list__tab-menu button {
  opacity: 1;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-tag {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  user-select: none;
  white-space: nowrap;
}
.dremio-tag .dremio-tag__hover-only {
  visibility: hidden;
}
.dremio-tag:hover .dremio-tag__hover-only {
  visibility: visible;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-tag {
  padding: 2px;
  font-size: 13px;
}
.dremio-tag dremio-icon,
.dremio-tag img,
.dremio-tag svg {
  block-size: 20px;
  inline-size: 20px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-tooltip {
  background: var(--dremio-tooltip--background);
  border-radius: var(--dremio-tooltip--border-radius);
  box-shadow: var(--dremio-tooltip--shadow);
  color: var(--dremio-tooltip--color);
  line-height: var(--dremio--tooltip--line-height);
  font-size: var(--dremio-tooltip--font-size);
  font-weight: var(--dremio-tooltip--font-weight);
  padding-block: calc(var(--dremio-tooltip--padding) - 2px);
  padding-inline: var(--dremio-tooltip--padding);
  z-index: 1000;
  transition-property: opacity, transform;
  will-change: transform;
}
.dremio-tooltip--bottom {
  transform-origin: bottom;
}
.dremio-tooltip--top {
  transform-origin: top;
}
.dremio-tooltip--left {
  transform-origin: left;
}
.dremio-tooltip--right {
  transform-origin: right;
}
.dremio-tooltip--sm {
  width: 175px;
}
.dremio-tooltip__arrow {
  position: absolute;
  transform: rotate(45deg);
  background: var(--dremio-tooltip--arrow--background);
  block-size: var(--dremio-tooltip--arrow--size);
  inline-size: var(--dremio-tooltip--arrow--size);
}
.dremio-tooltip__child-wrap {
  display: inline-block;
}

.echarts-tooltip {
  background-color: var(--dremio-tooltip--background) !important;
  border: var(--dremio-tooltip--border) !important;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.echarts-tooltip {
  --dremio-tooltip--background: var(--fill--popover);
  --dremio-tooltip--border: transparent;
}

.dremio-tooltip {
  --dremio-tooltip--background: var(--color--neutral--700);
  --dremio-tooltip--border-radius: var(--dremio--radius--05);
  --dremio-tooltip--color: white;
  --dremio-tooltip--line-height: 20px;
  --dremio-tooltip--font-size: 14px;
  --dremio-tooltip--font-weight: 400;
  --dremio-tooltip--padding: var(--dremio--spacing--1);
  --dremio-tooltip--shadow: var(--dremio--shadow--layer-1);
  --dremio-tooltip--arrow--background: var(--dremio-tooltip--background);
  --dremio-tooltip--arrow--size: 8px;
  transition-duration: 100ms;
  opacity: 0;
  transform: scale(0.8);
}
.dremio-tooltip-rich {
  --dremio-tooltip--background: var(--fill--popover);
  --dremio-tooltip--color: var(--text--primary);
}
.dremio-tooltip-enter, .dremio-tooltip-appear {
  opacity: 0;
  transform: scale(0.8);
}
.dremio-tooltip-enter-active, .dremio-tooltip-appear-active, .dremio-tooltip-appear-done, .dremio-tooltip-enter-done, .dremio-tooltip--open {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.dremio-tooltip-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.dremio-tooltip-exit-active {
  opacity: 0;
  transform: scale(0.8);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-divider {
  block-size: 1px;
  inline-size: 100%;
  background-color: var(--border--neutral);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dremio-label-inline {
  flex-direction: column;
}
.dremio-label__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.dremio-label__help {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--text--faded);
}
.dremio-label__icon:hover {
  color: var(--icon--hover);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.dataset-summary-overlay {
  inline-size: 300px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dataset-summary-overlay__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.dataset-summary-overlay__secondary-content {
  color: var(--text--faded);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.tabpanel-tablist {
  display: flex;
}
.tabpanel-tablist button {
  box-sizing: border-box;
  color: var(--text--faded);
  background-color: transparent;
  border: none;
  width: 160px;
  position: relative;
}
.tabpanel-tablist button[aria-selected=true] {
  color: var(--text--brand);
}
.tabpanel-tablist button[aria-selected=true]:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: var(--fill--brand--solid);
  position: absolute;
  bottom: 0;
  left: 0;
}
.tabpanel-tablist button:hover, .tabpanel-tablist button:focus {
  color: var(--text--brand);
}
.tabpanel-tablist button:disabled {
  cursor: not-allowed;
  color: var(--text--disabled);
}
.tabpanel-tablist button:disabled:after {
  background-color: var(--color--neutral--200);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.visualization-legend {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  font-size: 13px;
  flex-wrap: wrap;
}
.visualization-legend__segment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.visualization-legend__segment__chip {
  border-radius: 2px;
  height: 12px;
  width: 12px;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* stylelint-disable selector-class-pattern */
.mantine-Input-invalid {
  border-color: var(--fill--danger--solid) !important;
  color: inherit !important;
}

.mantine-InputWrapper-error {
  color: var(--fill--danger--solid) !important;
}

.mantine-InputWrapper-label {
  font-weight: 400 !important;
  inline-size: 100%;
  color: inherit;
}

.mantine-TextInput-icon--text .mantine-TextInput-icon {
  color: var(--text--primary);
  background-color: var(--fill--secondary);
  inset: 1px;
  border-end-start-radius: var(--dremio--radius--1);
  border-start-start-radius: var(--dremio--radius--1);
}

.mantine-ScrollArea-viewport > div > div {
  padding: var(--dremio--spacing--05) 0 !important;
}

.mantine-Select-dropdown {
  border: none;
  color: inherit;
  background-color: var(--fill--popover);
  border-radius: var(--dremio--radius--1);
}

.mantine-Select-item {
  border-radius: 0;
  color: var(--text--primary);
}

.mantine-Select-item[data-hovered=true] {
  background-color: var(--fill--primary--hover);
}

.mantine-Select-item[data-selected=true] {
  color: var(--text--primary);
  background-color: var(--fill--primary--selected);
}
.mantine-Select-item[data-selected=true]:hover {
  background-color: var(--fill--primary--selected--hover);
}

.mantine-Input-input {
  block-size: 32px;
  min-block-size: 32px;
  line-height: 32px;
  background-color: var(--fill--primary);
  color: var(--text--primary);
  border-color: var(--border--neutral--solid);
}
.mantine-Input-input:not(:disabled):hover {
  border-color: var(--border--hover);
}
.mantine-Input-input:disabled {
  color: var(--text--disabled);
  background-color: var(--fill--disabled);
  opacity: 1;
}

.mantine-Select-rightSection {
  pointer-events: none;
}

.mantine-NumberInput-control {
  border: none;
  color: var(--icon--primary);
}
.mantine-NumberInput-control:not(:disabled):hover {
  background-color: var(--fill--secondary);
}
.mantine-NumberInput-control:disabled {
  color: var(--icon--disabled);
}

.mantine-Checkbox-icon {
  color: var(--fill--primary);
}

.mantine-Checkbox-input {
  border-color: var(--border--neutral--solid);
  background-color: var(--fill--primary);
}
.mantine-Checkbox-input:hover {
  cursor: pointer;
  border-color: var(--border--hover);
}
.mantine-Checkbox-input:focus {
  outline-offset: 2px;
  outline: 2px solid var(--fill--toggle--checked--hover);
}
.mantine-Checkbox-input:checked {
  background-color: var(--fill--toggle--checked);
  border-color: var(--fill--toggle--checked);
}
.mantine-Checkbox-input:checked:hover {
  background-color: var(--fill--toggle--checked--hover);
  border-color: var(--fill--toggle--checked--hover);
}

.mantine-Menu-dropdown {
  background-color: var(--fill--popover);
  border-color: transparent;
}
.mantine-Menu-dropdown .mantine-Menu-item {
  color: var(--text--primary);
  background-color: inherit;
}
.mantine-Menu-dropdown .mantine-Menu-item:hover {
  background-color: var(--fill--primary--hover);
}

.mantine-Menu-divider {
  border-color: var(--border--neutral);
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
  background-color: var(--bg--sunken);
  color: var(--color--sunken);
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports not (prefers-color-scheme: light) {
  .dremio-auto-system {
    color-scheme: light;
  }
  .dremio-auto-system ::selection {
    background: var(--color--brand--100);
    color: var(--text--primary);
  }
}
@media (prefers-color-scheme: light) {
  .dremio-auto-system {
    color-scheme: light;
  }
  .dremio-auto-system ::selection {
    background: var(--color--brand--100);
    color: var(--text--primary);
  }
}
@media (prefers-color-scheme: dark) {
  .dremio-auto-system {
    color-scheme: dark;
  }
  .dremio-auto-system ::selection {
    background: var(--color--brand--600);
    color: var(--text--primary);
  }
}
@media (prefers-contrast: more) {
  .dremio-auto-system {
    color-scheme: light;
  }
  .dremio-auto-system ::selection {
    background: var(--color--brand--100);
    color: var(--text--primary);
  }
}

.dremio-light {
  color-scheme: light;
}
.dremio-light ::selection {
  background: var(--color--brand--100);
  color: var(--text--primary);
}
@media (prefers-contrast: more) {
  .dremio-light ::selection {
    background: var(--color--brand--200);
    color: var(--text--primary);
  }
}

.dremio-dark {
  color-scheme: dark;
}
.dremio-dark ::selection {
  background: var(--color--brand--600);
  color: var(--text--primary);
}

mark {
  background: var(--fill--mark);
  border-radius: 2px;
  color: inherit;
}

input,
textarea {
  font-feature-settings: "calt" off;
}

/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*# sourceMappingURL=index.css.map */